import { faBezierCurve, faGear, faGears, faMobileScreen, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ContactUsComponent from '../../../components/ContactUsComponent'

const developmentProcess = [
  {
    name: "Architecture",
    icon: faBezierCurve,
    description: "We use our expertise to create the architecture of the application.This determines the way major components interact with each other,making sure your application is flexible and scalable."
  },
  {
    name: "Design",
    icon: faMobileScreen,
    description: "Our UX/UI experts passionately create a user-friendly and intuitive design.  Designers work closely with our React.JS engineers to create an immersive user experience."
  },
  {
    name: "Coding",
    icon: faGear,
    description: "By following Agile development principles, our team focuses on frequent deliveries, active customer feedback while keeping the timeline and iterative approach."
  },
  {
    name: "Testing",
    icon: faUser,
    description: "Our QA engineers will test and ensure that the application works and meets the functional and business requirements."
  },
  {
    name: "Support & Maintenance",
    icon: faGears,
    description: "Our team provides ongoing support and maintenance of your product.",
  }
]

const WebDevelopment = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1503252947848-7338d3f92f31?auto=format&fit=crop&q=80&w=1631&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
          backgroundSize: "100% 80vh"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[80vh] pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">Web Development</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#E5E7EB" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="w-full items-center justify-center bg-gray-200">
        <div className="relative text-center text-white px-4 md:px-12">
          <h1 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold text-black pt-20">
            FROM IDEA TO REAL PRODUCT
          </h1>
          <br />
          <div className='py-4'>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-4xl font-normal text-black break-all">
              WE PROVIDE FULL LIFECYCLE
            </p>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-4xl font-normal text-black break-all">
              SOFTWARE DEVELOPMENT SERVICES
            </p>
          </div>
          <hr className="my-8 border-gray-400" />
        </div>

        <div className="flex flex-col md:flex-row px-12">
          <div className="md:w-1/2 flex justify-center items-center">
            <img src="../images/service.png" alt="web develpoment" className="w-3/4 md:w-full rounded-full" />
          </div>

          <div className="md:w-1/2 px-1 md:px-12 py-14">
            <p className="text-xl font-medium text-center md:text-left text-black">
              We use a holistic approach to study each of our client's business model.
              That helps us better understand the project's goal
              and to offer the most suitable solutions for achieving it.
              <br /><br />
              We present to the customer smart and effective solutions for your business and
              a cost-effective plan of development that suits the project's time & money resources.
            </p>
          </div>
        </div>
      </section>

      <section>
        <div className="text-white py-12 px-2 md:px-12" style={{ backgroundColor: "#222222" }}>
          <h2 className="text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-center mb-8 pt-10">
            Development Process
          </h2>
          <p className="text-base md:text-xs lg:text-2xl xl:text-lg px-4 md:px-12 font-medium text-center">
            Our Scrum Product Owners are in charge of the organization of the development process.
          </p>

          <div className="flex flex-col md:flex-row justify-center pt-10">
            {developmentProcess.map((item, index) => (
              <div key={index} className="body--container p-4 md:w-1/2 lg:w-1/3 xl:w-1/4">
                <div className="flex flex-col items-center mb-4 pt-2 md:pt-8">
                  <FontAwesomeIcon icon={item.icon} className="w-10 h-10 mb-4" />
                  <h3 className="text-lg md:text-xl font-bold mb-2 p-1">{item.name}</h3>
                  <p className="text-base text-center p-0.5 md:text-left">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

     <ContactUsComponent />
    </>
  )
}

export default WebDevelopment