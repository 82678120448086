import { Link } from "react-router-dom";
import useActiveNavbar from "../hooks/useActiveNavbar"

const ContactUsComponent = () => {
    const { setSelected }: any = useActiveNavbar();

    return (
        <section
            style={{ backgroundImage: `url('../images/body-footer.jpg')`, backgroundSize: "100% 100%" }}
            className="hero--container flex flex-col justify-center items-center gap-4 w-full h-full bg-no-repeat md:bg-origin-border pt-40 pb-20 py-8 md:py-12 lg:py-16 xl:py-20 2xl:py-24">
            <h1 className="text-center text-white text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold">Our team is ready to boost your business</h1>
            <p className="text-center text-white text-sm sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-semibold">“Tailored. Flexible. Competitive.”</p>
            <Link
                to={"/contact-us"}
                className="w-fit bg-slate-500 hover:bg-slate-700 text-white font-semibold py-4 px-8 rounded uppercase"
                onClick={() => setSelected("/contact-us")}>
                Get in touch
            </Link>
        </section>
    )
}

export default ContactUsComponent