import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const CeclModel = () => {
    return (
        <div className="bg-[#fff] w-full flex flex-col">
            <section
                style={{ backgroundImage: `url('../images/online-banking.png')`, backgroundSize: "100% 60vh" }}
                className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
                <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
                    Banking, Business, Financial Risk
                </p>
                <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">CECL Model Risk Application</h1>

                <div className='flex justify-center items-ceter gap-4 mt-10'>
                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faClock} className="w-4" />
                        <p className="blog-publish-date">2023-04-25</p>
                    </div>

                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faComment} className="w-4" />
                        <p className="blog-publish-date">10 Comments</p>
                    </div>

                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faBookmark} />
                        <p className="blog-publish-date">600 Views</p>
                    </div>

                </div>

            </section>

            <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
                <div className="mx-auto max-w-screen-md">

                    <p className='pb-4 text-justify leading-7'>The Current Expected Credit Loss (CECL) model is a new accounting standard for the financial industry that aims to provide better insight into the credit risk of a bank’s portfolio. The CECL model has been designed to replace the traditional incurred loss model with a forward-looking approach that is based on the expected credit losses (ECL) of a portfolio. The new standard has implications for banks of all sizes and requires significant changes to their credit loss modeling processes. In this blog, we will explore the concept of CECL model risk and compliance, as well as some best practices for banks to ensure they meet their regulatory obligations.</p>

                    <p className='pb-4 text-justify leading-7'><strong>CECL Model Risk</strong> <br />

                        The implementation of the CECL model has introduced new challenges for banks in terms of model risk management. Model risk refers to the potential for errors or inaccuracies in the models used to estimate credit losses, which can result in incorrect financial statements and potentially lead to regulatory sanctions. To mitigate the risk of model error, banks must ensure that their models are tested, validated, and reviewed on a regular basis to ensure that they are accurate and reliable. There are several sources of CECL model risk, including data quality, model design, assumptions, and implementation. Data quality is crucial for the accuracy of any model, and the CECL model is no exception. Banks need to ensure that their data is complete, accurate, and consistent across all portfolios. Model design and assumptions are also critical for CECL compliance, and banks must ensure that their models are designed and calibrated appropriately based on their portfolios’ unique characteristics. Finally, implementation risk refers to the risk of errors or inaccuracies in the process of implementing the model. Banks need to ensure that their implementation processes are robust and that they have appropriate governance and control frameworks in place to manage implementation risk.</p>

                    <img src="../images/cecl.jpg" alt="" className='mx-auto mt-3 mb-6 w-full' />

                    <p className='pb-4 text-justify leading-7'><strong>CECL compliance</strong> is a significant challenge for banks, and failure to comply can result in regulatory sanctions, reputational damage, and financial loss. The new standard requires banks to estimate their expected credit losses over the life of a loan, which requires a more comprehensive data collection and analysis process. Banks need to develop new models, modify existing models, and invest in data management infrastructure to comply with the new standard.</p>

                    <p className='pb-4 text-justify leading-7'>There are several best practices that banks can follow to ensure CECL compliance. These include: <br />

                        1. Developing a comprehensive CECL policy: A CECL policy should outline the bank’s approach to complying with the new standard, including data management, model development, validation, and implementation.
                        <br />
                        2. Developing and testing models: Banks need to develop models that are appropriate for their portfolios and that are accurate and reliable. These models must be tested and validated to ensure that they are compliant with the new standard.
                        <br />
                        3. Investing in data management infrastructure: CECL compliance requires a comprehensive data management process, including data collection, data cleansing, and data analysis. Banks need to invest in data management infrastructure to ensure that they have the right data to comply with the new standard.
                        <br />
                        4. Maintaining robust governance and control frameworks: Banks need to have robust governance and control frameworks in place to manage CECL model risk and ensure that their models are accurate and reliable.</p>


                    <p className='pb-4 text-justify leading-7'>The CECL model is a significant change for banks, requiring them to develop new models, modify existing models, and invest in data management infrastructure to comply with the new standard. CECL model risk and compliance are crucial for banks, and failure to comply can result in regulatory sanctions, reputational damage, and financial loss. To ensure compliance, banks need to develop a comprehensive CECL policy, invest in data management infrastructure, and maintain robust governance and control frameworks. By following best practices, banks can mitigate CECL model risk and comply with the new standard, providing better insight into the credit risk of their portfolios.</p>

                    <p className='pb-4 text-justify leading-7'>The successful completion of the complex CECL process is a major achievement for the Data Repo software project team. It reflects their commitment, persistence, and diligence in understanding and implementing the credit risk measurement and modeling framework, which is a critical component of the software project. The team’s deep knowledge and expertise are evident in their successful completion of this complex process.</p>

                    <p className='pb-4 text-justify leading-7'>Moreover, the successful implementation of the CECL requires coordination across multiple departments, including IT, risk management, finance, and accounting. The team’s ability to collaborate effectively and work together demonstrates their strong project management skills, clear communication strategy, and collaborative work culture. Their dedication to achieving this goal is a testament to their leadership and teamwork.</p>

                    <p className='pb-4 text-justify leading-7'>In summary, the successful completion of the CECL is a significant milestone for our software project, showcasing the team’s expertise, dedication, and collaborative spirit. It demonstrates our readiness to manage credit risks effectively, prepare for the future, and achieve our project goals.</p>

                </div>

            </section>

            <section className="mb-8">
                <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
                    <div className="flex flex-wrap gap-2">

                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
                    </div>
                    <div className="flex items-center gap-3 ">
                        <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} /> share
                            </a>
                        </div>
                        <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} /> tweet
                            </a>
                        </div>
                        <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
                            </a>
                        </div>
                        <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} /> linkedin
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default CeclModel;

