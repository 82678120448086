import ContactUsComponent from "../../../components/ContactUsComponent";

const ExtendedTeamServices = () => {
  return (
    <div className="bg-white-500 w-full">
      <section
        style={{ backgroundImage: `url('../images/extended-team-hero.jpg')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">EXTENDED TEAM</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#FFF" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>
      <section className="max-w-screen-xl mx-auto px-4 py-8">

        <h1 className=" text-2xl md:text-5xl font-bold pt-2 mb-2">What we offer</h1>
        <p className=" text-base md:text-base mt-4 mb-6 text-justify">We are a mix of professional and motivated people: developers, designers, project managers, and business analysts. Data Repo is happy to offer the experience of our employees for extending your team and working together on your business’s success.</p>

        <h1 className=" text-2xl md:text-5xl font-bold mt-4 mb-6 pt-2">
          What is an extended team?</h1>
        <p className=" text-base md:text-base text-justify">An extended team is a long-term way of cooperation between DataPro and our clients that is not ended after the project release. It is based on tight collaboration between the customer’s native team and our employees. To establish efficient interaction between our developers and the client’s team, we work according to the Agile system.</p>

        <hr className="mt-12" />
      </section>

      <section className="max-w-screen-xl mx-auto px-4 py-8">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-8">
            <img src="../images/extended-team.jpg" alt="business-logo" className="max-w-full" />
          </div>
          <div className="md:w-1/2 mt-4 md:mt-0">
            <h1 className="text-2xl md:text-5xl font-bold mb-4">Agile processes at Data Repo</h1>
            <p className="my-5 text-justify">
              Depending on the project goals, we choose Scrum or Kanban frameworks for the organization of our work. This helps us to keep pace with the market and quickly adapt the process to the client’s demands.
            </p>
            <p className="mb-5 text-justify">The Scrum system is about team organization and strategic planning. We divide the work is divided into short sprints and set the goals for each one. At the end of the sprint, the team analyzes the performance and updates the customer about the results. By comparing sprints with each other, we improve the efficiency of the work.</p>
            <p className="mb-5 text-justify">Kanban is about team balance and moderate load. We divide the work on the project into the stages: Planning, Developing, Testing, Deploying, etc. The average speed of completing each stage is the main measure of effectiveness in Kanban.</p>
          </div>
        </div>
      </section>




      <ContactUsComponent />



    </div>
  )
}

export default ExtendedTeamServices;