import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const ChatgptReplaceHuman = () => {
    return (
        <div className="bg-[#fff] w-full flex flex-col">
            <section
                style={{ backgroundImage: `url('../images/chatgpt-replace-human-work.png')`, backgroundSize: "100% 60vh" }}
                className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
                <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
                    Software trends, Artificial Intelligence
                </p>
                <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">CAN CHATGPT REPLACE HUMAN WORK?</h1>

                <div className='flex justify-center items-ceter gap-4 mt-10'>
                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faClock} className="w-4" />
                        <p className="blog-publish-date">2023-03-15</p>
                    </div>

                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faComment} className="w-4" />
                        <p className="blog-publish-date">10 Comments</p>
                    </div>

                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faBookmark} />
                        <p className="blog-publish-date">600 Views</p>
                    </div>

                </div>

            </section>

            <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
                <div className="mx-auto max-w-screen-md">

                    <p className='pb-4 text-justify leading-7'>AI, including chatbot technology like ChatGPT, has the potential to automate certain tasks and improve efficiency in various industries. However, it is important to note that AI is not meant to replace human work entirely, but rather to augment and assist it.</p>

                    <p className='pb-4 text-justify leading-7'>In certain industries, such as customer service or data entry, chatbots can handle repetitive and routine tasks, allowing human employees to focus on more complex and creative tasks. In other industries, such as healthcare or finance, AI can assist human professionals in making more accurate diagnoses or investment decisions. However, there are certain tasks that AI is not yet capable of performing, such as tasks that require emotional intelligence or creativity. Additionally, the use of AI raises ethical concerns, such as job displacement and the need for the retraining of employees.</p>

                    <p className='pb-4 text-justify leading-7'>Overall, while AI has the potential to transform various industries, it is important to approach its implementation thoughtfully and consider the implications for human workers. A balance must be found between the benefits of AI and the preservation of human jobs.</p>

                    <img src="../images/ai-replace-human.jpg" alt="" className='mx-auto mt-3 mb-6 w-full' />

                    <p className='pb-4 text-justify leading-7'><strong>Benefits of ChatGPT</strong> <br />
                        ChatGPT, as a language model AI, offers several benefits in comparison to human work in certain tasks and industries. Some of these benefits include:</p>


                    <p className='pb-4 text-justify leading-7'><strong><em>Efficiency </em></strong>– ChatGPT can process large amounts of data and generate written content quickly and accurately. This can save time and increase productivity in industries such as writing, data entry, and customer service.</p>

                    <p className='pb-4 text-justify leading-7'><strong><em>24/7 availability </em></strong>– ChatGPT does not require rest or breaks and can be available to interact with customers or complete tasks at any time.</p>

                    <p className='pb-4 text-justify leading-7'><strong><em>Consistency </em></strong>– ChatGPT can provide consistent, accurate responses to customer inquiries or generate written content with minimal errors.</p>

                    <p className='pb-4 text-justify leading-7'><strong><em>Scalability </em></strong>– ChatGPT can handle an unlimited number of interactions or tasks, making it well-suited for industries with a high volume of data or customer interactions.</p>

                    <p className='pb-4 text-justify leading-7'><strong>ChatGPT vs. Humans</strong> <br /> On the other hand, humans offer several benefits that ChatGPT currently lacks:</p>

                    <p className='pb-4 text-justify leading-7'><strong><em>Emotional intelligence </em></strong>– Humans have the ability to understand and respond to emotions, which is important in industries such as customer service, sales, and healthcare.</p>

                    <p className='pb-4 text-justify leading-7'><strong><em>Creativity </em></strong>– Humans have the ability to think outside the box and come up with novel solutions, which is important in industries such as design, advertising, and product development.</p>

                    <p className='pb-4 text-justify leading-7'><strong><em>Adaptability </em></strong>– Humans have the ability to adapt to changing situations and environments, which is important in industries such as management, customer service, and customer service.</p>

                    <p className='pb-4 text-justify leading-7'><strong><em>Human Interaction </em></strong>– Humans can provide a personal touch and human connection that a language model AI like ChatGPT can’t, as it lacks human emotions.</p>

                    <p className='pb-4 text-justify leading-7'>All in all, while ChatGPT offers several benefits in certain tasks, it is important to note that it cannot replace human work entirely. Both ChatGPT and humans have their own strengths and weaknesses, and the best results can be achieved by using the two in conjunction.</p>
                    
                </div>

            </section>

            <section className="mb-8">
                <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
                    <div className="flex flex-wrap gap-2">

                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
                    </div>
                    <div className="flex items-center gap-3 ">
                        <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} /> share
                            </a>
                        </div>
                        <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} /> tweet
                            </a>
                        </div>
                        <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
                            </a>
                        </div>
                        <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} /> linkedin
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default ChatgptReplaceHuman;

