import { faBriefcase, faPeopleGroup, faWallet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ContactUsComponent from '../../../components/ContactUsComponent';

const DevelopmentCenterServices = () => {
  return (
      <div className="bg-white-500 w-full">
          <section
                style={{ backgroundImage: `url('../images/html-css-collage-concept.jpg')`, backgroundSize: "100% 60vh" }}
                className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
                <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">DEVELOPMENT CENTER</h1>
                <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
                    <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300"  preserveAspectRatio="none">
                        <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
                        <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
                        <path  fill="#FFF" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
                    </svg>
                </div>
            </section>
          <section className="max-w-screen-xl mx-auto px-4 py-8">
          <p className=" text-yellow-600 text-xs md:text-xs font-bold uppercase mb-4">DEVELOPMENT CENTER</p>
          <h1 className=" text-2xl md:text-5xl font-bold uppercase pt-2 mb-4">What we offer</h1>
          <p className=" text-base md:text-base  py-2.5 text-justify">Data Repo team offers full-lifecycle web & mobile development services covering each and every stage of the development process. We start with planning the infrastructure when discussing the requirements. Our work does not end even after release – we continue maintaining and constantly improving the software.</p>

          <hr className="mt-12" />
          </section>
          
          <section className="max-w-screen-xl mx-auto px-4 py-8">
            <div className="flex flex-col md:flex-row items-center">
              <div className="md:w-1/2 md:pr-4">
                <img src="../images/development-center.jpg" alt="business-logo" className="max-w-full" />
              </div>
              <div className="md:w-1/2 mt-4 md:mt-0">
                <h1 className="text-2xl md:text-5xl font-bold mb-4">Development Center</h1>
                <p className="my-5 text-justify">
                Our developers are not only proficient in modern technologies, but also have a solid business background and strong analytic skills. This helps to develop a suitable solution for your idea with the perfect user experience.
                </p>
                <p className="mb-5 text-justify">In our work, we use Agile methodologies. This approach helps us to adjust our work to the requirements of the client and the product functionality – to the changing demands of the market.</p>
              </div>
            </div>
          </section>

          <section className="bg-black bg-opacity-90 py-8">
            <div className="max-w-screen-xl mx-auto flex flex-wrap justify-center gap-4">
              <div className="w-full sm:w-1/2 lg:w-1/4 xl:w-1/4 p-4">
                <div className="p-4">
                  <div className="flex items-center justify-center mb-5">
                    
                    <div className="text-3xl text-blue-500 mr-8">
                      <FontAwesomeIcon icon={faWallet} />
                    </div>
                    
                    <h2 className="text-2xl font-bold text-white">Cost-efficient development</h2>
                  </div>
                  
                  <p className='text-white opacity-70 text-justify'>Based on the time and budget resources, our analysts create the roadmap of development. We determine which critical features should be implemented in the MVP and which one will improve the product later. This approach allows us to save money, speed up the development, and keep in pace with the market requirements.</p>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg-w-1/4 xl:w-1/4 p-4">
                <div className="p-4">
                  <div className="flex items-center justify-center  mb-5">
                    
                    <div className="text-3xl text-blue-500 mr-8">
                      <FontAwesomeIcon icon={faPeopleGroup} />
                    </div>
                    
                    <h2 className="text-2xl font-bold text-white">Experienced team</h2>
                  </div>
                  
                  <p className='text-white opacity-70 text-justify'>Data repo team consists of skilled and motivated developers, project managers, business analysts, and QA engineers. We work in cooperation with your team in order to dive into your business and create a working and efficient software solution.</p>
                </div>
              </div>
              <div className="w-full sm:w-1/2 lg:w-1/4 xl:w-1/4 p-4">
                <div className="p-4">
                  <div className="flex items-center justify-center mb-5">
                    
                    <div className="text-3xl text-blue-500 mr-8">
                      <FontAwesomeIcon icon={faBriefcase} />
                    </div>
                    
                    <h2 className="text-2xl font-bold text-white">Efficient team management</h2>
                  </div>
                  
                  <p className='text-white opacity-70 text-justify'>Working in accordance with the Agile principles, we organized an effective system of interaction between our team and the client’s team. Following Agile principles, we split the working process into short sprints that are finished by updating customers with the product demo.</p>
                </div>
              </div>
            </div>
          </section>
          <ContactUsComponent />
                
          
      </div>
  )
}

export default DevelopmentCenterServices;