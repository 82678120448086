import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import ContactUsComponent from '../../components/ContactUsComponent';

const bodyContainer = [
  {
    name: "RD Rental",
    link: "/rd-rental",
    imgPath: "../images/rd-rental-software.png"
  },
  {
    name: "My Church Directory",
    link: "/church-directory",
    imgPath: "../images/church.jpg"
  },
  {
    name: "Bell POS System",
    link: "/pos-system",
    imgPath: "../images/bellpos-system.png"
  },
  {
    name: "Event Management System",
    link: "/event-management-system",
    imgPath: "../images/event-management-software.png"
  },
  {
    name: "JJ Kit",
    link: "/jj-kit",
    imgPath: "../images/jjkit.png"
  },
  {
    name: "JJ Timesheet",
    link: "/jj-timesheet",
    imgPath: "../images/jjtimesheet.png"
  },
];

const Product = () => {
  return (
    <div className="bg-[#F4F5FA] w-full flex flex-col">
      <section
        style={{ backgroundImage: `url('../images/expertise-hero-img.jpg')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">Products</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#F4F5FA" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16 md:gap-8">
        {bodyContainer.map((item, index) => (
          <div
            key={index}
            style={{ backgroundImage: `url('${item.imgPath}')`, backgroundSize: "100% 320px" }}
            className="relative body--view w-full h-80 py-8 px-6 rounded-md bg-no-repeat bg-cover flex flex-row items-end hover:bg-slate-500">
            <div className="absolute inset-0 bg-black opacity-50 rounded-md"></div>
            <h4 className="relative text-center w-full text-white text-2xl md:text-sm lg:text-2xl font-semibold z-10">{item.name}</h4>
            <Link
              to={item.link}
              className="relative w-12 h-12 p-4 rounded-full bg-transparent text-white flex flex-col justify-center items-center border-2 hover:text-slate-400 hover:border-slate-400 z-10"
              onClick={() => { }}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        ))}
      </section>

      <ContactUsComponent />
    </div>
  );
}

export default Product;