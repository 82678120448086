import ContactUsComponent from '../../../components/ContactUsComponent'

const nlpTechniques = [
  {
    number: "01",
    name: "Lemmatization and stemming",
    description: "Stemming and lemmatization are the first two steps to build an NLP project. They represent the field's core concepts and are often the first techniques you will implement on your journey to be an NLP master."
  },
  {
    number: "02",
    name: "Keywords extraction",
    description: "Keyword extraction is an NLP technique used for text analysis. It is often used as a first step to summarize the main ideas of a text and to deliver the key ideas presented in the text."
  },
  {
    number: "03",
    name: "Named Entity Recognition (NER)",
    description: "NER is a technique used to extract entities from a body of a text used to identify basic concepts within the text, such as people's names, places, dates, etc."
  },
  {
    number: "04",
    name: "Topic Modelling",
    description: "Multiple algorithms can be used to model a topic of text, such as Correlated Topic Model, Latent Dirichlet Allocation, and Latent Sentiment Analysis. The most commonly used approach is the Latent Dirichlet."
  },
  {
    number: "05",
    name: "Summarization",
    description: "Text summarization is the process of reducing a large body of text into a smaller chuck containing the text's main message. This technique is often used in long news articles and to summarize research papers.",
  },
  {
    number: "06",
    name: "Sentiment Analysis",
    description: "Sentiment analysis can be implemented using either supervised or unsupervised techniques. We use a supervised technique called Naive Bayes algorithm to perform sentiment analysis.",
  }
]

const SentimentAnalysis = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1592659762303-90081d34b277?auto=format&fit=crop&q=80&w=1373&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
          backgroundSize: "100% 80vh"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[80vh] pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">SENTIMENT ANALYSIS</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#E5E7EB" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="w-full items-center justify-center bg-gray-200">
        <div className="max-w-screen-lg mx-auto py-8">
          <p className=" text-orange-600 text-base md:text-sm font-bold mb-2 text-center md:text-left">MACHINE LEARNING</p>
          <h1 className=" text-3xl md:text-4xl font-bold pt-2 mb-4 text-center md:text-left">Sentiment analysis</h1>

          <div className="flex flex-col md:flex-row">
            <div className="px-8 md:px-1 md:pr-2 py-6">
              <p className="text-xl font-medium text-center md:text-left text-black">
                The most famous, well-known, and used NLP technique is sentiment analysis.
                This technique's core function is to extract the sentiment behind a
                body of text by analyzing the containing words.
                <br /><br />
                The technique's most simple results lay on a scale with 3 areas, negative,
                positive, and neutral. The algorithm can be more complex and advanced.
                If the result is a negative number, then the sentiment behind the text has a
                negative tone to it, and if it is positive, then some positivity in the text.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="text-white py-12 px-2 md:px-12" style={{ backgroundColor: "#222222" }}>
          <h2 className="text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-center mb-8 pt-10">
            NLP Techniques
          </h2>

          <div className="flex flex-wrap md:flex-row pt-4">
            {nlpTechniques.map((item, index) => (
              <div key={index} className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-1/3">
                <div className="flex flex-col items-left mb-4 pt-2 md:pt-8">
                  <h1 className="text-6xl font-bold text-gray-500 mb-4">{item.number}</h1>
                  <h3 className="text-lg md:text-xl font-bold mb-2 p-1" style={{ color: "#ff993f" }}>{item.name}</h3>
                  <p className="text-base text-left p-0.5">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <ContactUsComponent />
    </>
  )
}

export default SentimentAnalysis

