import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import ContactUsComponent from '../../components/ContactUsComponent'


const bodyContainer = [
  {
    name: "RD Rental",
    link: "/case-studies/rd-rental",
    imgPath: "../images/rd-rental.png"
  },
  {
    name: "Church Directory",
    link: "/case-studies/church-directory",
    imgPath: "../images/church-directory.png"
  },
  {
    name: "POS System",
    link: "/case-studies/pos-system",
    imgPath: "../images/pos-system.png"
  },
  {
    name: "Event Management System",
    link: "/case-studies/event-management-system",
    imgPath: "../images/event-management-system.png"
  }
]

const CaseStudies = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1553877522-43269d4ea984?auto=format&fit=crop&q=80&w=1470&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D")',
          backgroundSize: "100% 80vh"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[80vh] pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">Case Studies</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="white" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col md:flex-row gap-16 md:gap-8">
        {bodyContainer.map((item, index) => (
          <div
            key={index}
            style={{ backgroundImage: `url('${item.imgPath}')`, backgroundSize: "100% 320px", backgroundColor: "rgba(0, 0, 0, 4.5)" }}
            className="body--view w-full h-80 py-8 px-6 rounded-md bg-no-repeat bg-cover flex flex-row items-end hover:bg-slate-800">

            <h4 className="text-center w-full text-white text-2xl md:text-sm lg:text-xl font-semibold">{item.name}</h4>
            <Link
              to={item.link}
              className={`w-8 h-8 p-2 rounded-full bg-transparent text-white flex flex-col justify-center items-center border-2 hover:text-slate-500 hover:border-slate-500`}
              onClick={() => { }}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Link>
          </div>
        ))}
      </section>

      <ContactUsComponent />
    </>
  )
}

export default CaseStudies