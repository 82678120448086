import { faFacebookF, faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import useActiveNavbar from '../hooks/useActiveNavbar';

const firstColumn = [
    {
        name: "Home",
        link: "/"
    },
    {
        name: "Why us",
        link: "/why-us"
    },
    {
        name: "Expertise",
        link: "/expertise"
    },
    {
        name: "Services",
        link: "/services"
    },
    {
        name: "Case Studies",
        link: "/case-studies"
    }
];

const secondColumn = [
    {
        name: "Blog",
        link: "/blog"
    },
    {
        name: "Team",
        link: "/team"
    },
    {
        name: "Careers",
        link: "/careers"
    },
    {
        name: "Contact",
        link: "/contact-us"
    },
    {
        name: "Privacy policy",
        link: "/privacy-policy"
    }
];

const Footer = () => {
    const { setSelected }: any = useActiveNavbar();
    const location = useLocation();

    useEffect(() => {
        setSelected(location.pathname);
        // eslint-disable-next-line
    },[location])


    return (
        <footer className="bg-slate-400 w-full h-50 px-8 pt-10 pb-4 text-center">
            <div className="flex flex-1 flex-col md:flex-row gap-4 md:items-end">
                <div className="flex flex-row md:flex-col items-center gap-8 md:gap-4 w-2/4 md:w-1/6">
                    <img className="logo-img w-full md:w-3/4 h-20" src="../images/logo.png" alt="logo" />
                    <div className="flex flex-row gap-1">
                        <Link to="https://www.facebook.com/" className="text-white hover:text-slate-400 text-sm p-1 bg-gray-500 w-8 h-8 rounded-full"><FontAwesomeIcon icon={faFacebookF} /></Link>
                        <Link to="https://twitter.com/" className="text-white hover:text-slate-400 text-sm p-1 bg-gray-500 w-8 h-8 rounded-full"><FontAwesomeIcon icon={faXTwitter} /></Link>
                        <Link to="https://linkedin.com/" className="text-white hover:text-slate-400 text-sm p-1 bg-gray-500 w-8 h-8 rounded-full"><FontAwesomeIcon icon={faLinkedinIn} /></Link>
                    </div>
                </div>

                <div className="flex flex-1 flex-row w-full">
                    <ul className="w-1/2 text-start">
                        {firstColumn.map((item, index) => (
                            <li key={index} className="group w-fit font-semibold hover:text-white">
                                <Link to={item.link} onClick={() => setSelected(item.link)}>{item.name}</Link>
                                <div className="transform duration-1000 ease-in-out h-0.5 w-0 group-hover:w-full group-hover:bg-white group-hover:opacity-80" />
                            </li>
                        ))}
                    </ul>

                    <ul className="w-1/2 text-start">
                        {secondColumn.map((item, index) => (
                            <li key={index} className="group w-fit font-semibold hover:text-white">
                                <Link to={item.link} onClick={() => setSelected(item.link)}>{item.name}</Link>
                                <div className="transform duration-1000 ease-in-out h-0.5 w-0 group-hover:w-full group-hover:bg-white group-hover:opacity-80" />
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="flex flex-1 flex-col">
                    <h3 className="text-white font-bold text-lg w-fit">Contact Us</h3>
                    <div className="flex flex-col md:flex-row gap-4">
                        <ul className="w-1/2 text-start">
                            <li className="font-semibold">USA</li>
                            <li className="font-semibold">24624 Interstate 45 N Suite 200, Spring, TX 77386</li>
                            <li className="font-semibold">832-736-1101</li>
                            <li className="font-semibold">info@datarepo.tech</li>

                        </ul>

                        <ul className="w-1/2 text-start">
                            <li className="font-semibold">USA</li>
                            <li className="font-semibold">54 Sugar Creek Center Blvd STE 300, Sugar Land, TX 77478</li>
                            <li className="font-semibold">832-736-1101</li>
                            <li className="font-semibold">info@datarepo.tech</li>
                        </ul>
                    </div>
                </div>
            </div>

            <hr className="bg-white w-full h-0 5 mt-16 mb-4" />
            <span className="w-full text-white">&copy; {new Date().getFullYear()} Data Repo. All Rights Reserved. </span>
        </footer>
    )
}

export default Footer