import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const DatasetCollectionForAi = () => {
    return (
        <div className="bg-[#fff] w-full flex flex-col">
            <section
                style={{ backgroundImage: `url('../images/training-ai-models.png')`, backgroundSize: "100% 60vh" }}
                className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
                <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
                    Artificial Intelligence, Machine Learning, News
                </p>
                <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">Collecting News Datasets And Training AI Models</h1>

                <div className='flex justify-center items-ceter gap-4 mt-10'>
                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faClock} className="w-4" />
                        <p className="blog-publish-date">2023-09-09</p>
                    </div>

                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faComment} className="w-4" />
                        <p className="blog-publish-date">4 Comments</p>
                    </div>

                    <div className="flex items-center gap-2 text-white">
                        <FontAwesomeIcon icon={faBookmark} />
                        <p className="blog-publish-date">284 Views</p>
                    </div>

                </div>

            </section>

            <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
                <div className="mx-auto max-w-screen-md">

                    <p className='pb-4 text-justify leading-7'><strong>Collecting a News Dataset with Various Categories and Using Machine Learning to Train a Model</strong></p>

                    <p className='pb-4 text-justify leading-7'>The world is inundated with an overwhelming amount of information, and it can be difficult to keep up with the latest news and events. However, with the help of machine learning algorithms, we can automate the process of categorizing news articles and analyzing them to provide insights into what’s happening in the world.</p>

                    <p className='pb-4 text-justify leading-7'><strong>Collecting the News Dataset</strong></p>

                    <p className='pb-4 text-justify leading-7'>To create a news dataset, we need to first decide what categories we want to include. Some common categories might include sports, politics, entertainment, technology, business, and world news. Once we’ve decided on our categories, we need to gather news articles from various sources.</p>


                    <p className='pb-4 text-justify leading-7'>There are several ways to collect news articles, including web scraping and using APIs. One popular API for news data is the News API, which provides access to headlines and articles from over 30,000 news sources worldwide. Another popular option is the Google News API, which allows you to search for news articles by keyword, category, or location.</p>

                    <p className='pb-4 text-justify leading-7'>Once we have gathered our news articles, we need to preprocess the data by cleaning it up and removing any unnecessary information. This might include removing stop words, punctuation, and special characters, as well as converting all text to lowercase.</p>

                    <img src="../images/ai-dataset.png" alt="" className='mx-auto mt-3 mb-6 w-full' />

                    <p className='pb-4 text-justify leading-7'><strong>Training the Machine Learning Model</strong></p>

                    <p className='pb-4 text-justify leading-7'>Now that we have our cleaned and preprocessed news dataset, we can use machine learning algorithms to train a model to classify news articles into their respective categories.</p>

                    <p className='pb-4 text-justify leading-7'>One popular algorithm for text classification is the Naive Bayes algorithm. Naive Bayes is a probabilistic algorithm that calculates the probability of a news article belonging to each category based on the frequency of words in the article. The category with the highest probability is then assigned to the article.</p>
                   
                    <p className='pb-4 text-justify leading-7'>Another popular algorithm is the Support Vector Machine (SVM) algorithm, which works by finding the optimal hyperplane that separates the news articles into their respective categories.</p>

                    <p className='pb-4 text-justify leading-7'>To train our machine learning model, we need to split our dataset into training and testing sets. The training set is used to train the model, while the testing set is used to evaluate its performance.</p>

                    <p className='pb-4 text-justify leading-7'>Once we’ve trained our model, we can use it to classify new news articles into their respective categories. This can be done by preprocessing the new article in the same way we preprocessed our original dataset and then passing it through our trained model to get a predicted category.</p>

                    <p className='pb-4 text-justify leading-7'>In conclusion, collecting a news dataset with various categories and using machine learning to train a model to classify news articles can be a powerful tool for automating the categorization of news articles and providing insights into what’s happening in the world. By using popular algorithms such as Naive Bayes or SVM, we can quickly and accurately classify news articles into their respective categories, saving time and resources while providing valuable insights.
                    <br />
I am happy to announce that our team at Data Repo has recently completed a successful model training project for one of our clients!</p>

                    <p className='pb-4 text-justify leading-7'>Using cutting-edge machine learning techniques and a deep understanding of our client’s data, we were able to develop and train a highly accurate model that achieved impressive results. Our team worked tirelessly to fine-tune the model, optimizing its performance and ensuring that it met our client’s specific needs.</p>

                    <p className='pb-4 text-justify leading-7'>The project was a great success, and our client was thrilled with the results. They were able to gain valuable insights from their data, which they could use to inform critical business decisions.</p>
                    
                    <p className='pb-4 text-justify leading-7'>At Data Repo, we are committed to delivering high-quality solutions that exceed our clients’ expectations. This project is a testament to the hard work and dedication of our team, and we are proud of the results we were able to achieve.</p>

                    <p className='pb-4 text-justify leading-7'>We look forward to continuing to help our clients leverage the power of data to drive their businesses forward. If you’re interested in learning more about our data science and machine learning services, please don’t hesitate to reach out to us. We would be more than happy to discuss how we can help you achieve your data-driven goals.</p>


                </div>

            </section>

            <section className="mb-8">
                <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
                    <div className="flex flex-wrap gap-2">

                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
                        <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
                    </div>
                    <div className="flex items-center gap-3 ">
                        <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faFacebook} /> share
                            </a>
                        </div>
                        <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faTwitter} /> tweet
                            </a>
                        </div>
                        <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
                            </a>
                        </div>
                        <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
                            <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} /> linkedin
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}

export default DatasetCollectionForAi;

