import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import useActiveNavbar from "../hooks/useActiveNavbar";


const Header = () => {
    const [navbarHeader, setNavbarHeader] = useState<any[]>([
        {
            name: "Product",
            link: "/product",
            isChildVisible: false,
            child: [
                {
                    name: "RD Rental",
                    link: "/rd-rental",
                },
                {
                    name: "Church Directory",
                    link: "/church-directory",
                },
                {
                    name: "POS System",
                    link: "/pos-system",
                },
                {
                    name: "Event Management System",
                    link: "/event-management-system",
                },
                {
                    name: "JJ Kit",
                    link: "/jj-kit",
                },
                {
                    name: "JJ Timesheet",
                    link: "/jj-timesheet",
                }
            ]
        },
        {
            name: "EXPERTISE",
            link: "/expertise",
            isChildVisible: false,
            child: [
                {
                    name: "Web Development",
                    link: "/web-development",
                },
                {
                    name: "Mobile Development",
                    link: "/mobile-development",
                },
                {
                    name: "Artifical Intelligence Expertise",
                    link: "/artificial-intelligence-expertise",
                }
            ]
        },
        {
            name: "SERVICES",
            link: "/services",
            isChildVisible: false,
            child: [
                {
                    name: "IT & Business Consulting",
                    link: "/it-business-consulting",
                },
                {
                    name: "Development Center Services",
                    link: "/development-center-services",
                },
                {
                    name: "Extended Team Services",
                    link: "/extended-team-services",
                }
            ]
        },
        {
            name: "CASE STUDIES",
            link: "/case-studies",
            isChildVisible: false,
            child: []
        },
        {
            name: "BLOG",
            link: "/blog",
            isChildVisible: false,
            child: []
        },
        {
            name: "CONTACT US",
            link: "/contact-us",
            isChildVisible: false,
            child: []
        }
    ]);
    const { selected, setSelected }: any = useActiveNavbar();
    const [toggle, setToggle] = useState<boolean>(false);
    const [headerBackgroundChange, setHeaderBackgroundChange] = useState<boolean>(false);
    const [visibleTopBtn, setVisibleTopBtn] = useState<boolean>(false);

    const progessBtn = useRef<HTMLButtonElement>(null);

    useEffect(() => {
        const toggleVisible = () => {
            const scrolled = document.documentElement.scrollTop;

            if (scrolled > 300) {
                setVisibleTopBtn(true);
            } else if (scrolled <= 300) {
                setVisibleTopBtn(false);
            }

            if (scrolled > 0) {
                setHeaderBackgroundChange(true);
            } else {
                setHeaderBackgroundChange(false);
            }
        };

        window.addEventListener('scroll', toggleVisible);

        return () => window.addEventListener('scroll', toggleVisible);
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <header className="header-container transition-all duration-500 absolute md:fixed right-0 left-0 z-50 top-0">
                <div className={`header-top ${headerBackgroundChange ? "bg-transparent md:bg-white" : "bg-transparent"} flex justify-between w-full max-w-screen-3xl mx-auto font-semibold h-16 px-5 lg:px-14 xl:px-26`}>
                    <div className="flex justify-center items-center gap-3 md:gap-4 mr-5 w-2/6 md:w-1/6 py-2">
                        <NavLink to="/" className="z-10">
                            <img className="logo-img h-12 md:h-14" src="../images/logo.png" alt="logo" />
                        </NavLink>

                    </div>
                    <div className="flex items-center sm:gap-3 md:gap-8 md:mr-10 lg:mr-20">
                        {navbarHeader.map((data, index) => (
                            data.child.length === 0 ?
                                <NavLink
                                    to={data.link}
                                    className="group h-16 flex justify-center flex-col"
                                    key={index}
                                    onClick={() => setSelected(data.link)}
                                >
                                    <span className={`text-sm text-center font-semibold ${headerBackgroundChange ? "text-white md:text-black" : "text-white"} opacity-80 cursor-pointer pt-3 md:flex md:items-center hidden`}>
                                        {data.name}
                                    </span>
                                    <div
                                        className={`h-0.5 transform duration-500 ease-linear ${selected === data.link
                                            ? `${headerBackgroundChange ? "bg-black" : "bg-white"} opacity-80 w-full`
                                            : `${headerBackgroundChange ? "group-hover:bg-black" : "group-hover:bg-white"} group-hover:opacity-80 w-0 group-hover:w-full`
                                            }`}
                                    />
                                </NavLink>
                                :
                                <div
                                    key={index}
                                    className="with-child-desktop-view group relative hidden md:flex"
                                >
                                    <NavLink
                                        to={data.link}
                                        className="flex flex-row justify-center items-center h-16 relative"
                                        onClick={() => setSelected(data.link)}
                                    >
                                        <div>
                                            <span className={`text-sm text-center font-semibold ${headerBackgroundChange ? "text-white md:text-black" : "text-white"} opacity-80 cursor-pointer pt-3 md:flex md:items-center hidden`}>
                                                {data.name}
                                            </span>
                                            <div
                                                className={`h-0.5 transform duration-500 ease-linear ${selected === data.link
                                                    ? `${headerBackgroundChange ? "bg-black" : "bg-white"} opacity-80 w-full`
                                                    : `${headerBackgroundChange ? "group-hover:bg-black" : "group-hover:bg-white"} group-hover:opacity-80 w-0 group-hover:w-full`
                                                    }`}
                                            />
                                        </div>
                                        <FontAwesomeIcon icon={faAngleDown} className="absolute top-1/2 -right-4 ml-0.5 text-xs text-slate-500" />
                                    </NavLink>

                                    <div className={`aboutus-container-desktop absolute z-[1000] mt-16 left-0  hidden group-hover:block`}>
                                        <ul className={` w-fit list-none overflow-hidden border-[#808080] ${headerBackgroundChange ? "bg-[#F4F5FA]" : "bg-white"} bg-clip-padding text-left text-base shadow-lg`}>
                                            <li className="block w-full whitespace-nowrap bg-transparent p-4">
                                                {data.child.map((item: any, itemIndex: number) => (
                                                    <NavLink
                                                        to={item.link}
                                                        key={itemIndex}
                                                        className="w-full whitespace-nowrap bg-transparent flex flex-row items-center gap-2"
                                                        onClick={() => setSelected(item.link)}
                                                    >
                                                        <span className="before:hover:content-[' '] before:hover:bg-slate-500 before:hover:w-3 before:hover:h-0.5 before:hover:mr-1 px-1 py-2 text-sm text-center font-normal text-black opacity-80 cursor-pointer md:flex md:items-center hidden">
                                                            {item.name}
                                                        </span>
                                                    </NavLink>
                                                ))}
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                        ))}

                        <button
                            className="w-12 h-12 relative focus:outline-none md:hidden"
                            onClick={() => setToggle(!toggle)}
                        >
                            <div className="block w-5 absolute left-6 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                                <span
                                    className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle ? "rotate-45" : "-translate-y-1.5"
                                        }  
                `}
                                ></span>
                                <span
                                    className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle && "opacity-0"
                                        }`}
                                ></span>
                                <span
                                    className={`block absolute h-0.5 w-7 text-white bg-current transform transition duration-500 ease-in-out ${toggle ? "-rotate-45" : "translate-y-1.5"
                                        }`}
                                ></span>
                            </div>
                        </button>
                    </div>

                    <div
                        className={`absolute w-full top-[65px] left-0 right-0 block md:hidden z-20 ${toggle ? "visible" : "invisible"
                            }`}
                    >
                        <div
                            className="bg-white p-3 text-start flex flex-col">
                            {navbarHeader.map((data, index) => (
                                data.child.length === 0 ?
                                    <NavLink
                                        to={data.link}
                                        className="group p-2"
                                        key={index}
                                        onClick={() => {
                                            setSelected(data.link);
                                            setToggle(false);
                                        }}>
                                        <span className="text-md font-bold text-black hover:text-slate-700">{data.name}</span>
                                    </NavLink>
                                    :
                                    <div
                                        className="py-2"
                                        key={index}>
                                        <NavLink
                                            to={data.link}
                                            className="group p-2"
                                            onClick={() => {
                                                setSelected(data.link);
                                                setToggle(false);
                                            }}>
                                            <span className="text-md font-bold text-black hover:text-slate-700">{data.name}</span>
                                        </NavLink>

                                        <button className="group text-md font-bold text-slate-500 w-full flex flex-row justify-end gap-0.5"
                                            onClick={() =>
                                                setNavbarHeader((prevNavbar) => {
                                                    const result = [...prevNavbar];
                                                    result[index] = {
                                                        ...result[index],
                                                        isChildVisible: !data.isChildVisible,
                                                    };
                                                    return result;
                                                })
                                            }
                                        >
                                            <span className={`block ${data.isChildVisible ? "w-4" : "w-1 transform duration-1000 ease-in-out"} h-1 bg-slate-500 rounded-full`}></span>
                                            <span className={`block  ${data.isChildVisible ? "w-0" : "w-1 transform duration-500 ease-in-out"} h-1 bg-slate-500 rounded-full`}></span>
                                            <span className={`block  ${data.isChildVisible ? "w-0" : "w-1 transform duration-500 ease-in-out"} h-1 bg-slate-500 rounded-full`}></span>
                                        </button>

                                        <div
                                            style={{ display: data.isChildVisible ? "flex" : "none" }}
                                            className={`aboutus-container flex flex-col`}>
                                            {data.child.map((item: any, itemIndex: number) => (
                                                <NavLink
                                                    to={item.link}
                                                    className="aboutus-item group p-2"
                                                    key={itemIndex}
                                                    onClick={() => {
                                                        setSelected(item.link);
                                                        setToggle(false);
                                                    }}>
                                                    <span className="text-md text-black hover:text-slate-700">{item.name}</span>
                                                </NavLink>
                                            ))}
                                        </div>
                                    </div>
                            ))}
                        </div>
                        <div className="divider bg-slate-500 w-full h-1" />
                    </div>

                </div>

                <div className="divider bg-white w-full h-[1px] shadow-inner" />

                <button
                    ref={progessBtn}
                    className={`top-btn ${visibleTopBtn ? "flex" : "hidden"} w-12 h-12 rounded-full bg-transparent text-slate-500 border-2 border-slate-500 flex justify-center items-center fixed bottom-[65px] right-5 shadow-[0_3px_10px_rgb(0,0,0,0.2)] hover:text-slate-700`}
                    onClick={() => scrollToTop()}>
                    <div className="w-8 h-8 flex justify-center items-center bg-transparent">
                        <FontAwesomeIcon icon={faArrowUp} className="z-30" />
                    </div>
                </button>

            </header>
        </>
    );
}

export default Header; 