import { ReactNode, createContext, useState } from 'react';

const ActiveNavbarContext = createContext({});

export const ActiveNavBarProvider = ({ children }: { children: ReactNode }) => {
    const [selected, setSelected] = useState<string>("/");

    return (
        <ActiveNavbarContext.Provider value={{ selected, setSelected }}>
            {children}
        </ActiveNavbarContext.Provider>
    )
}

export default ActiveNavbarContext;