import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import ContactUsComponent from '../../../components/ContactUsComponent'

const bodyContainer = [
  {
    name: "Natural Language Processing",
    link: "/natural-language-processing",
    imgPath: "../images/web-develop.jpg"
  },
  {
    name: "Sentiment Analysis",
    link: "/sentiment-analysis",
    imgPath: "../images/mobile-develop.jpg"
  },
  {
    name: "Model Training",
    link: "/model-training",
    imgPath: "../images/ai.jpg"
  }
]

const naturalLanguageProcessing = [
  {
    number: "01",
    name: "Lemmatization and stemming",
    description: "Stemming and lemmatization are the first two steps to build an NLP project. They represent the field's core concepts and are often the first techniques you will implement on your journey to be an NLP master."
  },
  {
    number: "02",
    name: "Keywords extraction",
    description: "Keyword extraction is an NLP technique used for text analysis. It is often used as a first step to summarize the main ideas of a text and to deliver the key ideas presented in the text."
  },
  {
    number: "03",
    name: "Named Entity Recognition (NER)",
    description: "NER is a technique used to extract entities from a body of a text used to identify basic concepts within the text, such as people's names, places, dates, etc."
  },
  {
    number: "04",
    name: "Topic Modelling",
    description: "Multiple algorithms can be used to model a topic of text, such as Correlated Topic Model, Latent Dirichlet Allocation, and Latent Sentiment Analysis. The most commonly used approach is the Latent Dirichlet."
  },
  {
    number: "05",
    name: "Summarization",
    description: "Text summarization is the process of reducing a large body of text into a smaller chuck containing the text's main message. This technique is often used in long news articles and to summarize research papers.",
  },
  {
    number: "06",
    name: "Sentiment Analysis",
    description: "Sentiment analysis can be implemented using either supervised or unsupervised techniques. We use a supervised technique called Naive Bayes algorithm to perform sentiment analysis.",
  }
]

const ArtificialIntelligenceExpertise = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: 'url("https://images.pexels.com/photos/8386440/pexels-photo-8386440.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1")',
          backgroundSize: "100% 80vh"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[80vh] pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">ARTIFICAL INTELLIGENCE</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#E5E7EB" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="w-full items-center justify-center bg-gray-200">
        <div className="relative text-center text-white px-4 md:px-12">
          <h1 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-semibold text-black pt-20">
            FROM IDEA TO REAL PRODUCT
          </h1>
          <br />
          <div className='py-4'>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-4xl font-normal text-black break-all">
              WE PROVIDE FULL LIFECYCLE
            </p>
            <p className="text-lg md:text-xl lg:text-2xl xl:text-4xl font-normal text-black break-all">
              SOFTWARE DEVELOPMENT SERVICES
            </p>
          </div>
          <hr className="my-8 border-gray-400" />
        </div>

        <div className="flex flex-col md:flex-row px-12">
          <div className="md:w-1/2 flex justify-center items-center">
            <img src="../images/artificial-intelligence.png" alt="web develpoment" className="w-3/4 md:w-full rounded-full" />
          </div>

          <div className="md:w-1/2 px-1 md:px-12 py-14">
            <p className="text-xl font-medium text-center md:text-left text-black">
              With the rapid development of computer technologies, more and more companies are
              adopting machine learning algorithms. These algorithms increase the power of computers
              and teach them to solve non-trivial tasks without human intervention.
              <br /><br />
              Machine Learning offers many opportunities for businesses: data repocessing,
              modeling, predictive analytics. It helps companies reduce operating costs
              by automating the process of delivering new products and services.
              <br /><br />
              We develop advanced machine learning models and algorithms for the specific
              needs of your business.
            </p>
          </div>
        </div>

        <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24 flex flex-col md:flex-row gap-16 md:gap-8">
          {bodyContainer.map((item, index) => (
            <div
              key={index}
              style={{ backgroundImage: `url('${item.imgPath}')`, backgroundSize: "100% 320px" }}
              className="body--view w-full h-80 py-8 px-6 rounded-md bg-no-repeat bg-cover flex flex-row items-end hover:bg-slate-500">

              <h4 className="text-center w-full text-white text-2xl md:text-sm lg:text-2xl font-semibold">{item.name}</h4>
              <Link
                to={item.link}
                className={`w-12 h-12 p-4 rounded-full bg-transparent text-white flex flex-col justify-center items-center border-2 hover:text-slate-500 hover:border-slate-500`}
                onClick={() => { }}>
                <FontAwesomeIcon icon={faArrowRight} />
              </Link>
            </div>
          ))}
        </section>
      </section>

      <section>
        <div className="text-white py-12 px-2 md:px-12" style={{ backgroundColor: "#222222" }}>
          <h2 className="text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-semibold text-center mb-8 pt-10">
            Natural Language Processing
          </h2>

          <div className="flex flex-wrap md:flex-row pt-4">
            {naturalLanguageProcessing.map((item, index) => (
              <div key={index} className="body--container p-4 md:w-1/2 lg:w-1/2 xl:w-1/3">
                <div className="flex flex-col items-left mb-4 pt-2 md:pt-8">
                  <h1 className="text-6xl font-bold text-gray-500 mb-4">{item.number}</h1>
                  <h3 className="text-lg md:text-xl font-bold mb-2 p-1" style={{ color: "#ff993f" }}>{item.name}</h3>
                  <p className="text-base text-left p-0.5">{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <ContactUsComponent />
    </>
  )
}

export default ArtificialIntelligenceExpertise