import ContactUsComponent from "../../../components/ContactUsComponent";


const BusinessConsulting = () => {
  return (
    <div className="bg-white-500 w-full">
      <section
        style={{ backgroundImage: `url('../images/business-consulting-hero-image.jpg')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">IT & BUSINESS CONSULTING</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#FFF" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>
      <section className="max-w-screen-lg mx-auto px-4 py-8">
        <p className=" text-yellow-600 text-xs md:text-xs font-bold uppercase mb-4">IT & BUSINESS CONSULTING</p>
        <h1 className=" text-2xl md:text-5xl font-bold pt-2 mb-4">What we offer</h1>
        <p className=" text-base md:text-base pt-2 pb-1 text-justify">Data Repo is not just a software development company – we are your business consulting partner.</p>
        <p className=" text-base md:text-base text-justify">To build a successful business, it is essential to take into account lots of details. Our analysts will help you to figure out the challenges of your business and offer effective ways of solving them. The tech team will implement the ideas in a software system.</p>

        <hr className="mt-12" />
      </section>

      <section className="max-w-screen-xl mx-auto px-4 ">
        <div className="flex flex-col md:flex-row items-center">
          <div className="md:w-1/2 md:pr-4">
            <img src="../images/startup-support.jpg" alt="business-logo" className="max-w-full" />
          </div>
          <div className="md:w-1/2 mt-4 md:mt-0">
            <h1 className="text-2xl md:text-5xl font-bold mb-4">Startup support</h1>
            <p className="my-5 text-justify">
              Got a great startup idea? To succeed, you need to have a strong team, and to develop an MVP that attracts your first customers.
            </p>
            <p className="mb-5 text-justify">Data Repo can help you with solving both these challenges. Our skilled business analysts will consult you at every stage of our cooperation. We will deeply study your business logic and together decide on the functionality of MVP. After presenting your startup to the general public, the business specialists of DataPro will be happy to further support and improve your product.</p>
            <p className="mb-3 text-justify">The technical team will be in charge of the actual development process. Our developers will implement all the features you need in a perfectly working software. We also provide post-release support services, and our development team will gladly maintain your product.</p>
          </div>
        </div>
      </section>

      <section className="max-w-screen-xl mx-auto px-4 pt-4 pb-8">
        <div className="flex flex-col md:flex-row items-center">

          <div className="md:w-1/2 mt-4 md:mt-0">
            <h1 className="text-2xl md:text-5xl font-bold mb-4">Business automation</h1>
            <p className="my-5 text-justify">
              Does your business require digitalization? To succeed, it is not enough just to hire a software development company. You need specialists that will understand the organization and needs of your business
            </p>
            <p className="mb-5 text-justify">Here at Data Repo, we have a strong team of business analysts and project managers. They will study the logic of your company and identify the possible challenges of its digital transformation. After studying your business, our specialists will offer the variants of their solving with the help of software.</p>
            <p className=" mb-5 text-justify">Working in cooperation with the business specialists, the technical team will implement all your requirements in a stable and scalable software system.</p>
          </div>

          <div className="md:w-1/2 md:pl-4">
            <img src="../images/business-automation.jpg" alt="business-logo" className="max-w-full" />
          </div>
        </div>
      </section>


      <ContactUsComponent />


    </div>
  )
}

export default BusinessConsulting;