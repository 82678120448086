import { faAngleLeft, faAngleRight, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import ContactUsComponent from '../../components/ContactUsComponent'
import { Link } from 'react-router-dom'
import useActiveNavbar from '../../hooks/useActiveNavbar'

const heroContain = [
  {
    title: "Accelerate your idea with a trusted IT partner",
    subTitle: "Helping to solve your digital challenges",
    imgSrc: "../images/website-bg-1.jpg"
  },
  {
    title: "Advancing your product to market with custom software solutions",
    subTitle: "Web and Mobile Software Development",
    imgSrc: "../images/website-bg-1.png"
  },
  {
    title: "Accelerate your idea with a trusted IT partner",
    subTitle: "Helping to solve your digital challenges",
    imgSrc: "../images/website-bg-3.png"
  }
]

const aboutUs = [
  {
    icon: "../images/fill-color.png",
    name: "Web and Mobile",
    desc: "We craft web and mobile software solutions according to your business needs."
  },
  {
    icon: "../images/talk.png",
    name: "IT Consulting",
    desc: "We advise you on the best use of IT in achieving your business objectives."
  },
  {
    icon: "../images/earth-globe.png",
    name: "Extended Team",
    desc: "We extend your development needs and become a part of your team."
  }
];

const technologiesIcon = [
  "../images/vue.png",
  "../images/sales-force.png",
  "../images/aws.png",
  "../images/azure.svg",
  "../images/j-query.png",
  "../images/docker.png",
  "../images/mongodb.png",
  "../images/react.png",
  "../images/redis.png",
  "../images/laravel.png",
  "../images/python.png",
  "../images/mysql.png",
  "../images/nodejs.png"
];

const backEndSpecialists = [
  "Architecture design",
  "Backend & Frontend development",
  "Responsive design",
  "QA engineering",
  "Deployment"
];

const frontEndSpecialists = [
  "Project managers",
  "Backend & Frontend development",
  "Responsive design",
  "QA engineering",
  "Deployment"
];

const expertise = [
  {
    name: "Web Development",
    link: "/web-development",
    imgPath: "../images/web-develop.jpg"
  },
  {
    name: "Mobile Development",
    link: "/mobile-development",
    imgPath: "../images/mobile-develop.jpg"
  },
  {
    name: "Artificial Intelligence",
    link: "/artificial-intelligence-expertise",
    imgPath: "../images/ai.jpg"
  }
];

const team = [
  {
    name: " ",
    designation: " ",
    imgPath: "../images/web-develop.jpg"
  },
  {
    name: " ",
    designation: " ",
    imgPath: "../images/mobile-develop.jpg"
  }
];

const customerFeedback = [
  {
    customerName: "Customer Name",
    companyName: "Company Name",
    feedback: "We have worked with Data repo for more than six years. They have provided us with outstanding and innovative technical solutions for our multi-lingual websites. We would highly recommend Data repo for any web development needs"
  },
  {
    customerName: "Customer Name",
    companyName: "Company Name",
    feedback: "Data Repo is a great long-time partner for Bridge Marketing. With their custom application development and business automation solutions and support, we were able to achieve high-quality results. Their team is extremely timely and efficient in working with us through every step"
  },
  {
    customerName: "Customer Name",
    companyName: "Company Name",
    feedback: "The real strength that Data repo brings to the table is their ability to fully understand their customer needs, thoroughly evaluate and then flawlessly design the business concept. Through their vast experience and forward thinking, they will add value to your business concept that may not have been considered"
  }
];

const projectImg = [
  "../images/bridge.png",
  "../images/e-food-junction.png",
  "../images/direct-mail-io.png",
  "../images/rgm.png",
  "../images/ozmosys.png",
  "../images/efj.png"
]


const Home = () => {
  const { setSelected }: any = useActiveNavbar();
  const [counter, setCounter] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(1);

  const customerRef = useRef<HTMLDivElement>(null);
  const feedbackRef = useRef<HTMLDivElement>(null);
  const projectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const silderFunc = (slider: HTMLDivElement, updateIndex: boolean) => {
      let isDown: boolean = false;
      let startX: number;
      let scrollLeft: number;

      slider?.addEventListener('mousedown', (e) => {
        let rect = slider.getBoundingClientRect();
        isDown = true;

        slider.classList.add('active');
        startX = e.pageX - rect.left;
        scrollLeft = slider.scrollLeft;
      });

      slider?.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });

      slider?.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove('active');
      });

      slider?.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        let rect = slider.getBoundingClientRect();
        e.preventDefault();

        const x = e.pageX - rect.left;
        const walk = (x - startX);

        const viewWidth = slider.offsetWidth;
        slider.scrollLeft = scrollLeft - walk;

        if (updateIndex) {
          const newIndex = Math.round(scrollLeft / viewWidth);
          setCurrentIndex(newIndex);
        }
      });
    }

    if (customerRef.current) silderFunc(customerRef.current, false);
    if (feedbackRef.current) silderFunc(feedbackRef.current, true);
    if (projectRef.current) silderFunc(projectRef.current, false);

  }, []);

  const scrollToPrevtView = () => {
    if (feedbackRef.current && currentIndex > 0) {
      const container = feedbackRef.current;
      const viewWidth = container.offsetWidth;

      const currentScrollLeft = container.scrollLeft;
      const scrollTo = currentScrollLeft - viewWidth;

      container.scrollTo({ left: scrollTo, behavior: 'smooth' });
      setCurrentIndex(currentIndex - 1);
    }
  };

  const scrollToNextView = () => {
    if (feedbackRef.current && customerFeedback.length - 1 > currentIndex) {
      const container = feedbackRef.current;
      const viewWidth = container.offsetWidth;

      const currentScrollLeft = container.scrollLeft;
      const scrollTo = currentScrollLeft + viewWidth;

      container.scrollTo({ left: scrollTo, behavior: 'smooth' });
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToPrev = () => {
    if (counter === 0) setCounter(heroContain.length - 1);
    else setCounter(counter - 1);
  }

  const goToNext = () => {
    if (counter === heroContain.length - 1) setCounter(0);
    else setCounter(counter + 1);
  }

  return (
    <div className="bg-white w-full flex flex-col overflow-hidden">

      <section className="hero--container relative w-full h-[80vh] md:h-[100vh] overflow-hidden">
        <button
          className={` ${counter > 0 ? "block" : "hidden"}  absolute top-96 left-20 z-10 md:flex w-16 h-16 rounded-full bg-transparent text-white flex-col justify-center items-center border-2 hover:border-slate-700 animate-left-icon`}
          onClick={goToPrev}>
          <FontAwesomeIcon icon={faAngleLeft} size='lg' />
        </button>

        {heroContain.map((item, index) => (
          <div
            key={index}
            style={{ backgroundImage: `url('${item.imgSrc}')`, backgroundSize: "100% 112vh", transform: `translateY(-${counter * 100}%)` }}
            className={`transform ease-out duration-1000 bg-slate-300 relative flex flex-row justify-evenly px-8 items-center md:gap-4 lg:gap-8 w-full h-[80vh] md:h-[112vh] bg-no-repeat bg-fixed md:bg-origin-border`}>

            <div className="flex flex-col gap-16 flex-1">
              <h1 className="text-center text-white text-4xl  md:text-2xl lg:text-3xl xl:text-4xl 2xl:text-5xl font-bold animate-translate-text">{item.title}</h1>
              <p className="text-center text-white text-lg md:text-xl lg:text-2xl xl:text-3xl 2xl:text-4xl font-semibold animate-opacity-text">{item.subTitle}</p>
            </div>
          </div>
        ))}

        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="white" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>

        <button
          className={`hidden absolute top-96 right-20 z-10 md:flex w-16 h-16 rounded-full bg-transparent text-white flex-col justify-center items-center border-2 hover:border-slate-700 animate-right-icon`}
          onClick={goToNext}>
          <FontAwesomeIcon icon={faAngleRight} size='lg' />
        </button>

      </section>

      <section className="body--about-us--container flex flex-col justify-start items-center py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-4/6">
          <p className="text-start text-slate-500 text-sm font-semibold uppercase">Helping to solve your digital challenges</p>
          <h1 className="text-4xl font-bold text-black my-4">About us</h1>
          <h2 className="text-xl font-bold text-black">We provide your idea validation and strategic guidance through MVP creation and enterprise software development, all to align with your growth strategy.</h2>

          <div className="flex flex-col md:flex-row gap-4 md:gap-8 py-4">
            {aboutUs.map((item, index) => (
              <div key={index} className="flex flex-col py-4">
                <div className="flex flex-row justify-between">
                  <img src={item.icon} alt={item.name} className="w-8 md:w-6 lg:w-8 h-8 md:h-6 lg:h-8 text-slate-500 fill-slate-500" />
                  <p className="text-sm font-bold text-black">{item.name}</p>
                </div>

                <p className="text-sm font-semibold text-black">{item.desc}</p>
              </div>
            ))}
          </div>

        </div>

        <Link
          to={"/why-us"}
          className="w-fit bg-slate-500 hover:bg-slate-700 text-white font-semibold py-4 px-8 rounded uppercase"
          onClick={() => setSelected("/why-us")}>
          More About Us
        </Link>

      </section>

      <section className="body--technologies--container flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6 text-center">
          <h1 className="text-4xl font-bold text-black my-4">TECHNOLOGIES</h1>
          <h2 className="text-xl font-bold text-black">We are PHP experts:</h2>
          <h2 className="text-xl font-bold text-black">Symfony, Laravel, Yii &, etc.</h2>
          <h2 className="text-xl font-bold text-black">Building great products would not be possible without the knowledge and</h2>
          <h2 className="text-xl font-bold text-black">extensive usage of many other things, e.g.</h2>
          <h2 className="text-xl font-bold text-black">Python, PostgreSQL, MySQL, MongoDB, Redis, React.JS, Vue.JS, React Native, and many many more.</h2>

          <div className="technologies--icon-contaianer flex flex-row flex-wrap gap-4 pt-16">
            {technologiesIcon.map((item, index) => (
              <img key={index} src={item} alt={item.split("/")[item.split("/").length - 1]} width="120" height="110" />
            ))}
          </div>

        </div>
      </section>

      <section className="body--back-end--container flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6 text-center flex flex-col md:flex-row gap-8">
          <img src="../images/back-end.png" alt="back-end" />

          <div className="text--container text-start">
            <h2 className="text-4xl font-bold text-black uppercase">Back - End</h2>
            <p className="text-lg font-bold text-black py-4">Before starting the work on the project, we negotiate with the customer. From our side, the business and tech specialists discuss the requirements with the client&#8217;s team.</p>
            <ul style={{ listStyleImage: `url('../images/check-mark.png')` }} className="ml-6">
              {backEndSpecialists.map((item, index) => (
                <li key={index} className="text-lg">{item}</li>
              ))}
            </ul>
          </div>

        </div>

      </section>

      <section className="body--front-end--container flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6 text-center flex flex-col md:flex-row-reverse gap-8">
          <img src="../images/front-end.png" alt="front-end" />

          <div className="text--container text-start">
            <h2 className="text-4xl font-bold text-black uppercase">Front - End</h2>
            <p className="text-lg font-bold text-black py-4">Do you know that 80% of smartphone users are more likely to purchase from companies with modern mobile sites or apps?</p>
            <ul style={{ listStyleImage: `url('../images/check-mark.png')` }} className="ml-6">
              {frontEndSpecialists.map((item, index) => (
                <li key={index} className="text-lg">{item}</li>
              ))}
            </ul>
          </div>

        </div>
      </section>

      <section className="body--expertise--container bg-slate-500 flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6 text-center flex flex-col gap-8">
          <h2 className="text-4xl font-bold text-white uppercase">Expertise</h2>
          <p className="text-lg font-semibold text-white pb-4 uppercase">From Idea to Real Product</p>

          <div className="flex flex-col md:flex-row gap-16 md:gap-8">
            {expertise.map((item, index) => (
              <div
                key={index}
                style={{ backgroundImage: `url('${item.imgPath}')`, backgroundSize: "100% 320px" }}
                className="body--view w-full h-80 py-8 px-6 rounded-md bg-no-repeat bg-cover flex flex-row items-end hover:bg-slate-500">

                <h4 className="text-center w-full text-white text-2xl md:text-sm lg:text-2xl font-semibold">{item.name}</h4>
                <Link
                  to={item.link}
                  className={`w-12 h-12 p-4 rounded-full bg-transparent text-white flex flex-col justify-center items-center border-2 hover:text-slate-500 hover:border-slate-500`}
                  onClick={() => { }}>
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </div>
            ))}
          </div>

        </div>

      </section>

      <section className="body--send-message--container bg-slate-500 flex flex-col justify-start items-center mt-16 py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6 flex flex-col md:flex-row justify-center items-center gap-16 md:gap-[72px] lg:gap-20">
          <div className="flex flex-col gap-4 text-center">
            <h2 className="text-4xl font-bold text-white uppercase">From Idea to Real Product</h2>
            <p className="text-lg font-semibold text-white pb-4 uppercase">We provide full lifecycle software development services</p>
          </div>

          <>
            <Link
              to={"/contact-us"}
              className="w-fit h-auto bg-white hover:bg-slate-500 border border-white text-slate-500 hover:text-white font-semibold py-4 px-8 rounded uppercase"
              onClick={() => setSelected("/contact-us")}>
              Send a Message <span className='text-xl'>&#8594;</span>
            </Link>
          </>

        </div>

      </section>

      <section
        style={{ backgroundImage: `url('../images/team-home-bg.jpg')`, backgroundSize: "100% 100%" }}
        className="body--team--container bg-slate-500 flex flex-col justify-start items-center">
        <div className="w-full h-full backdrop-blur-sm py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
          <div className="w-full md:w-5/6flex flex-col text-center">

            <h2 className="text-4xl font-bold text-white">Our Team</h2>
            <p className="text-lg font-semibold text-white pb-4">The Data Repo team is a unique mix of motivated and creative employees.</p>

            <div
              ref={customerRef}
              className="flex flex-row gap-16 md:gap-8 justify-normal md:justify-center overflow-x-scroll no-scrollbar">
              {team.map((item, index) => (
                <div
                  key={index}
                  style={{ backgroundImage: `url('${item.imgPath}')`, backgroundSize: "100% 320px" }}
                  className="body--view shrink-0 w-full md:w-80 h-96 rounded-md bg-no-repeat bg-cover flex flex-col justify-end items-end hover:bg-slate-500">

                 

                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="body--customer--feedback--container w-full bg-white flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-20 xl:px-28 2xl:px-36">
        <div className="w-full text-center flex flex-col gap-8 justify-center items-center">
          <h2 className="text-4xl font-bold text-black">A few words from our customers</h2>

          <div className="hero--container bg-white relative flex flex-row justify-evenly px-8 items-center md:gap-4 lg:gap-8 w-full">

            <button
              className={`hidden md:flex w-12 h-12 rounded-full bg-transparent text-slate-500 flex-col justify-center items-center border-2 hover:border-slate-700`}
              onClick={scrollToPrevtView}>
              <FontAwesomeIcon icon={faArrowLeft} size='lg' />
            </button>

            <div
              ref={feedbackRef}
              className="flex flex-row gap-8 flex-1 overflow-x-scroll no-scrollbar">
              {customerFeedback.map((item, index) => (
                <div
                  key={index}
                  className="flex flex-col shrink-0 w-full md:px-10 justify-center items-center">
                  <img src="../images/double-quotes.png" alt="double-quotes" width="64" height="64" />
                  <p className="text-center text-black text-lg">{`${item?.feedback?.substring(0, 224)}...`}</p>
                  <p className="text-center text-slate-500 text-sm font-bold pt-8">{item?.customerName}</p>
                  <p className="text-center text-black text-sm ">{item?.companyName}</p>
                </div>
              ))}
            </div>

            <button
              className={`hidden md:flex w-12 h-12 rounded-full bg-transparent text-slate-500 flex-col justify-center items-center border-2 hover:border-slate-700`}
              onClick={scrollToNextView}>
              <FontAwesomeIcon icon={faArrowRight} size='lg' />
            </button>

          </div>

          <div className="hidden md:flex flex-row gap-1 mt-8">
            {customerFeedback.map((_, index) => (
              currentIndex === index ?
                <span key={index} className="w-6 h-1 bg-slate-500 rounded-full"></span>
                :
                <span key={index} className="w-6 h-1 bg-slate-300 rounded-full"></span>
            ))}
          </div>

          <Link
            to={"/case-studies"}
            className="w-fit h-auto mt-4 bg-slate-500 hover:bg-slate-700 text-white hover:text-white font-semibold py-4 px-8 rounded uppercase"
            onClick={() => setSelected("/case-studies")}>
            Read More
          </Link>
        </div>
      </section>

      <section className="body--project--container w-full bg-white flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-20 xl:px-28 2xl:px-36">
        <div className="w-full text-center flex flex-col gap-8 justify-center items-center">

          <div
            ref={projectRef}
            className="flex flex-row gap-16 flex-1 overflow-x-scroll no-scrollbar">
            {projectImg.map((item, index) => (
              <img key={index} src={item} alt={item.split("/")[item.split("/").length - 1]} className="flex w-full md:w-28 md:h-28" />
            ))}
          </div>

        </div>

      </section>

      <ContactUsComponent />

    </div>
  )
}

export default Home;