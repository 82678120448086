import ContactUsComponent from '../../components/ContactUsComponent'

const features = [
  "Agile Project Management",
  "Customizable Workflows",
  "Issue Tracking",
  "Real-Time Reporting and Dashboards",
  "Backlog Management",
  "Sprint Planning",
  "Kanban Boards",
  "Scrum Boards",
  "Advanced Search and Filtering",
  "Time Tracking",
  "Integrations with Development Tools",
  "User Permissions and Roles",
  "Notifications and Alerts",
  "Mobile Access",
];

const technologiesIcon = [
  "../images/react.png",
  "../images/azure.svg",
  "../images/azure-sql.png",
  "../images/nodejs.png",
  "../images/csharp.png",
];

const JjKit = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(../images/bg-casestudies-box.png)`,
          backgroundSize: "100% 80vh"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[80vh] pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">JJ Kit</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#E5E7EB" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="w-full pt-5 px-8 items-center justify-center bg-gray-200">
        <div className="flex flex-col md:flex-row px-16">
          <div className="md:w-1/2 flex justify-center items-center p-5">
            <img src="../images/jjkit-1.png" alt="car rental software dashboard" />
          </div>

          <div className="md:w-1/2 flex justify-center items-center p-5">
            <img src="../images/jjkit-2.png" alt="car rental software dashboard" />
          </div>
        </div>
      </section>

      <section className="w-full items-center justify-center bg-gray-200">
        <div className="max-w-screen-lg mx-auto py-8">
          <div className="px-8 md:px-1 md:pr-2 py-6">
            <div className="mb-4 text-lg font-normal text-center md:text-left text-gray-600">
              <p>
                <strong>Industry:</strong> Software Development; Project Management; Collaboration Tools
              </p>
              <p>
                <strong>Location:</strong> USA
              </p>
              <p>
                <strong>Areas of expertise:</strong> Back-end; Front-end; Quality assurance; Project management; Automation
              </p>
              <p>
                <strong>Technologies:</strong> React, Typescript, Azure, SQL
              </p>
              <p>
                <strong>Timeline:</strong> 2021-now
              </p>
            </div>

            <p className="text-xl font-normal text-center md:text-left text-gray-600">
              JJ Kit is a comprehensive project management tool designed to streamline workflows, enhance collaboration, and increase productivity. 
              It offers a suite of features that support agile project management, issue tracking, and team collaboration, making it an essential 
              tool for software development teams and other project-focused organizations.
            </p>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Key Features
              </h2>
              <ul className="list-disc pl-5 text-xl font-normal text-center md:text-left text-gray-600">
                {features.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Project Results
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600 pb-8">
                JJ Kit has empowered teams to manage their projects more efficiently, with customizable workflows, real-time 
                reporting, and extensive integrations. Users have reported significant improvements in project visibility, 
                collaboration, and overall productivity.
              </p>
              <ul className="list-disc pl-5 text-xl font-normal text-center md:text-left text-gray-600">
                <li>Enhanced project visibility and tracking with customizable dashboards and real-time reports.</li>
                <li>Streamlined workflows through automation rules and customizable issue tracking.</li>
                <li>Increased team collaboration with integrated communication tools and mobile access.</li>
                <li>Improved project delivery times with effective sprint planning and backlog management.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="body--technologies--container flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6">
          <h1 className="text-4xl font-bold text-black text-left my-4">TECHNOLOGIES</h1>
          <hr />
          <div className="technologies--icon-contaianer justify-center flex flex-row flex-wrap gap-4 pt-16">
            {technologiesIcon.map((item, index) => (
              <img key={index} src={item} alt={item.split("/")[item.split("/").length - 1]} width="100" height="100" className='mr-10' />
            ))}
          </div>
        </div>
      </section>
      <ContactUsComponent />
    </>
  )
}

export default JjKit;