import ContactUsComponent from '../../components/ContactUsComponent'

const deliverables = [
  "Implementing a comprehensive employee management system",
  "Enabling precise clock-in and clock-out tracking for accurate attendance",
  "Automating payroll processes to ensure timely and accurate payments",
  "Creating detailed user reporting structures to enhance organizational clarity",
  "Developing a robust scheduling tool for effective workforce management",
  "Integrating real-time data analytics for better decision-making",
  "Ensuring compliance with labor laws and regulations",
  "Providing a scalable solution to grow with your business",
  "Enhancing productivity by automating time-consuming tasks"
];

const technologiesIcon = [
  "../images/react.png",
  "../images/azure.svg",
  "../images/azure-sql.png",
  "../images/nodejs.png",
  "../images/csharp.png",
];

const JjTimesheet = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(../images/bg-casestudies-box.png)`,
          backgroundSize: "100% 80vh"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[80vh] pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">JJ Timesheet</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#E5E7EB" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="w-full pt-5 px-8 items-center justify-center bg-gray-200">
        <div className="flex flex-col md:flex-row px-16">
          <div className="md:w-1/2 flex justify-center items-center p-5">
            <img src="../images/jjtimesheet-1.png" alt="car rental software dashboard" />
          </div>

          <div className="md:w-1/2 flex justify-center items-center p-5">
            <img src="../images/jjtimesheet-2.png" alt="car rental software dashboard" />
          </div>
        </div>
      </section>

      <section className="w-full items-center justify-center bg-gray-200">
        <div className="max-w-screen-lg mx-auto py-8">
          <div className="px-8 md:px-1 md:pr-2 py-6">
            <div className="mb-4 text-lg font-normal text-center md:text-left text-gray-600">
              <p>
                <strong>Industry:</strong> Human Resources; Workforce Management
              </p>
              <p>
                <strong>Location:</strong> Global
              </p>
              <p>
                <strong>Areas of expertise:</strong> Employee management; Time tracking; Payroll; Reporting; Scheduling
              </p>
              <p>
                <strong>Technologies:</strong> React, Azure, SQL, Node.js, C#
              </p>
              <p>
                <strong>Timeline:</strong> 2020-now
              </p>
            </div>

            <p className="text-xl font-normal text-center md:text-left text-gray-600">
              JJTimesheet is a comprehensive tool designed to streamline employee management, ensuring efficient clock-in and clock-out tracking, payroll automation, detailed user reporting, and robust scheduling. It integrates real-time data analytics for better decision-making and ensures compliance with labor laws and regulations.
            </p>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Comprehensive Employee Management
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600">
                JJTimesheet provides a centralized platform to manage all employee data efficiently. From personal information and employment history to job details, everything is easily accessible, enabling better oversight and management.
              </p>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Accurate Clock-In and Clock-Out Tracking
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600">
                Employees can log their hours effortlessly, whether working on-site or remotely. This feature ensures accurate attendance tracking, which is crucial for payroll processing and operational efficiency.
              </p>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Automated Payroll Processing
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600">
                JJTimesheet automates payroll calculations based on logged hours, overtime, and deductions. Generate detailed payroll reports to ensure transparency and accuracy in employee payments.
              </p>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Detailed User Reporting Structures
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600">
                Understand your organizational hierarchy with ease. The user reporting feature clarifies who reports to whom, facilitating clear communication channels and streamlined management, essential for performance reviews and planning.
              </p>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Robust Scheduling
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600">
                Efficiently manage employee schedules, ensuring optimal coverage and adherence to labor laws. Employees can view their schedules in real-time, reducing confusion and ensuring awareness of upcoming shifts.
              </p>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Deliverables
              </h2>
              <ul className="list-disc pl-5 text-xl font-normal text-center md:text-left text-gray-600">
                {deliverables.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Project Results
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600 pb-8">
                JJTimesheet has helped organizations improve their operational efficiency, reduce errors in payroll, and enhance overall productivity. By automating time-consuming tasks, JJTimesheet enables businesses to focus on growth and strategic initiatives.
              </p>
              <ul className="list-disc pl-5 text-xl font-normal text-center md:text-left text-gray-600">
                <li>Improved accuracy in attendance and payroll processing.</li>
                <li>Enhanced organizational clarity through detailed reporting structures.</li>
                <li>Increased productivity by automating scheduling and payroll tasks.</li>
                <li>Compliance with labor laws and regulations ensured.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="body--technologies--container flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6">
          <h1 className="text-4xl font-bold text-black text-left my-4">TECHNOLOGIES</h1>
          <hr />
          <div className="technologies--icon-contaianer justify-center flex flex-row flex-wrap gap-4 pt-16">
            {technologiesIcon.map((item, index) => (
              <img key={index} src={item} alt={item.split("/")[item.split("/").length - 1]} width="100" height="100" className='mr-10' />
            ))}
          </div>
        </div>
      </section>

      <ContactUsComponent />
    </>
  )
}

export default JjTimesheet;