import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const LTI = () => {
  return (
    <div className="bg-[#fff] w-full flex flex-col">
      <section
        style={{ backgroundImage: `url('../images/lms-systems.png')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
          EdTech, SSO
        </p>
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">LTI Integration For LMS Systems</h1>

        <div className='flex justify-center items-ceter gap-4 mt-10'>
          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faClock} className="w-4" />
            <p className="blog-publish-date">14/03/2020</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faComment} className="w-4" />
            <p className="blog-publish-date">3 Comments</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faBookmark} />
            <p className="blog-publish-date">1150 Views</p>
          </div>

        </div>

      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="mx-auto max-w-screen-md">
          <p className='pb-4 text-justify leading-7'>LTI (Learning Tools Interoperability) is a standard developed by IMS Global Learning Consortium that allows educational systems and tools to communicate and exchange data with each other. It is widely used in the education industry to enable a seamless integration of third-party learning applications, such as online homework systems, simulations, and games, into learning management systems (LMS) like Blackboard, Brightspace, Canvas, and Moodle.</p>

          <img src="../images/lti-lms.png" alt="" className='mx-auto mt-3 mb-6 w-full' />

          <p className='pb-4 text-justify leading-7'>However, LTI integration can be challenging for both LMS administrators and developers of third-party learning applications. One of the main challenges is the lack of standardization among LMSs. Each LMS has its own set of APIs (Application Programming Interfaces) and requirements, making it difficult for developers to create a single integration solution that works across all platforms. As a result, developers often have to create custom integrations for each LMS, which can be time-consuming and costly.</p>

          <p className='pb-4 text-justify leading-7'>As a trusted IT partner, Data Repo Software Solutions helps solve the digital challenges of your business. Company professionals do not just write the code. They help you develop your startup MVP from scratch or apply digital transformation to your already established business. Every client business is studied and integrated well into the IT department. A team constantly maintains and improves even after a project is completed.</p>

          <p className='pb-4 text-justify leading-7'>Another challenge is the lack of documentation and support from LMS providers. Many LMS providers do not offer comprehensive documentation or support for LTI integration, leaving developers to figure out the process on their own. This can lead to delays in the integration process and increased development costs.</p>

          <p className='pb-4 text-justify leading-7'>Security is another concern when it comes to LTI integration. LTI relies on trust relationships between the LMS and third-party learning applications, and any security breach or vulnerability can compromise the integrity and privacy of the data exchanged between the two systems. It is important for both LMS administrators and developers to ensure that proper security measures are in place to protect the data of students and educators.</p>

          <p className='pb-4 text-justify leading-7'>Custom software development always gets you a unique and innovative product that scales your organization better. It improves your productivity and employee drive by integrating software with other programs. Your return on investment has nowhere to go in this way. In fact, you acquire ongoing technical support at every step during and after your product development.</p>

          <p className='pb-4 text-justify leading-7'>Overall, LTI integration can be a complex and challenging process, but it is a necessary step in providing a seamless and integrated learning experience for students and educators. By addressing the challenges of standardization, documentation, and security, LMS administrators and developers can work together to create a successful LTI integration that benefits all parties involved.</p>

          <p className=' text-justify leading-7'>The Data Repo team successfully completed the difficult software project of integrating LMS systems (Blackboard, Brightspace, Canvas, and Moodle) via the LTI with the educational platform on time and within budget. The project team worked tirelessly to overcome any challenges that arose, and their dedication paid off in the end. The company is proud of its achievement and looks forward to implementing the new software for the benefit of its clients.</p>

          
        </div>

      </section>

      <section className="mb-8">
  <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
    <div className="flex flex-wrap gap-2">
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
      
    </div>
    <div className="flex items-center gap-3 ">
      <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} /> share
        </a>
      </div>
      <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} /> tweet
        </a>
      </div>
      <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
        </a>
      </div>
      <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} /> linkedin
        </a>
      </div>
    </div>
  </div>
</section>
 
    </div>
  )
}

export default LTI;