import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const DataRepoSoftwareRewind = () => {
  return (
    <div className="bg-[#fff] w-full flex flex-col">
      <section
        style={{ backgroundImage: `url('../images/data-repo-software-clutch.png')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
          Software trends, Artificial Intelligence
        </p>
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">Data Repo Lauded As One Of The Most Recommended Companies</h1>

        <div className='flex justify-center items-ceter gap-4 mt-10'>
          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faClock} className="w-4" />
            <p className="blog-publish-date">2022-04-10</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faComment} className="w-4" />
            <p className="blog-publish-date">10 Comments</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faBookmark} />
            <p className="blog-publish-date">600 Views</p>
          </div>

        </div>

      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="mx-auto max-w-screen-md">

          <p className='pb-4 text-justify leading-7'>Data Repo Software is a digital development solution provider that focuses on strategic experience creation. Founded in 2014, we’ve been helping SMEs tackle their development challenges by utilizing modern technologies and industry best practices. Today, we’re a trusted delivery partner of cost-effective solutions to many companies.</p>

          <p className='pb-4 text-justify leading-7'>In 2021, we’ve been named as one of the leading providers of development services on Clutch, a B2B ratings and reviews platform. Clutch highlights the best-performing companies in an industry and location, and we’re proud to have been recognized by them.</p>

          <p className='pb-4 text-justify leading-7'>2021 was a great year for our team as we continue to deliver the high-quality solutions that our customers deserve. Let’s revisit the milestones that we’ve achieved in the past year, made possible by Clutch.</p>

          <p className='pb-4 text-justify leading-7'>Feedback is an important factor that determines the success of any business. In 2021, we’re proud to have collected a total of five reviews on Clutch. These reviews provide interested prospects with the most accurate information about our work and our relationship with our clients. What’s more exciting for us is the fact that we’ve accumulated an overall perfect score of five stars across all reviews!</p>

          <p className='pb-4 text-justify leading-7'>Furthermore, our clients have the freedom to share their thoughts about our services, and we encourage them to say everything they want to. For this reason, we couldn’t be happier to find out that we’ve achieved a 100% recommendation rate in 2021!</p>

          <img src="../images/data-repo-software clutch.png" alt="" className='mx-auto mt-3 mb-6 w-full' />

          <p className='pb-4 text-justify leading-7'>Take a look at our most recent 5-star review in 2021:</p>

            <p className='pb-4 text-justify leading-7'><em>“Data Repo was always willing to make changes on demand, even in a difficult technical situation.”</em> <br />

— Joye Preece, Project Manager, Travel Agency</p>

            <p className='pb-4 text-justify leading-7'>We’d like to take this opportunity to thank all of our clients, especially those who took the time to leave their kind feedback, for choosing our services. We’re excited to see what we can achieve in 2022!</p>
          
        </div>

      </section>

      <section className="mb-8">
  <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
    <div className="flex flex-wrap gap-2">
      
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
    </div>
    <div className="flex items-center gap-3 ">
      <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} /> share
        </a>
      </div>
      <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} /> tweet
        </a>
      </div>
      <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
        </a>
      </div>
      <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} /> linkedin
        </a>
      </div>
    </div>
  </div>
</section>
 
    </div>
  )
}

export default DataRepoSoftwareRewind;

