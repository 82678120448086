import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const DeveloperSyndrome = () => {
  return (
    <div className="bg-[#fff] w-full flex flex-col">
      <section
        style={{ backgroundImage: `url('../images/software-developer.png')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
          Software trends
        </p>
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">Overengineering: The Developer’s Syndrome</h1>

        <div className='flex justify-center items-ceter gap-4 mt-10'>
          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faClock} className="w-4" />
            <p className="blog-publish-date">2022-7-15</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faComment} className="w-4" />
            <p className="blog-publish-date">10 Comments</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faBookmark} />
            <p className="blog-publish-date">600 Views</p>
          </div>

        </div>

      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="mx-auto max-w-screen-md">

          <p className='pb-4 text-justify leading-7'>I’d like to start with what I call the “developer’s syndrome.” This is basically loving tech, innovation, and new trendy tools. Sounds harmless, huh?</p>

          <p className='pb-4 text-justify leading-7'>But it isn’t. Don’t misunderstand me; I’m not saying that developers shouldn’t fancy the cutting edge; it’s their essence, and often the reason they have become programmers in the first place. But using the latest trends shouldn’t be the whole point of developing a digital product for a client./</p>

          <p className='pb-4 text-justify leading-7'>The number one objective of developing a site or app is its utility. A working web app should do its job in a fast, accessible, and secure way, aiming to create a seamless user experience. But sometimes developers, perhaps because of their passion, can lose focus and put their own “developers’ experience” ahead of the user’s. This is, of course, a mistake: users’ and clients’ needs should always be the top priority.</p>

          <img src="../images/developer-syndrome.png" alt="developer-syndrome" />

          <h2 className=" text-2xl md:text-4xl font-bold  leading-9 mb-5">Unpopular Opinion:
Newest isn’t always best.</h2>
          <p className='pb-4 text-justify leading-7'>This is precisely what happens with the newest programming languages and frameworks, which are, of course, fantastic and highly usable for some digital products. But let’s face it: even though they are shinier and more enjoyable, they are not always necessary.</p>

          <p className='pb-4 text-justify leading-7'>Remember the base technologies? I’m talking about PHP, Apache, MySQL, and jquery. What about native javascript over the HTML and CSS? They don’t sound as trendy as others, right? But they provide quality results for clients. It’s true, Angular, React, Vue, Laravel, Symfony, Webpack, Gulp, Babel, and other kinds of technologies are great and add lots of value through cleaner code, documentation, and maintenance. Libraries allow us to save time by reusing code. Still, these newer programming languages often include plenty of unnecessary functions we don’t really need to make users’ and clients’ happy (in fact, they might slow the products’ performance).</p>

            <p className='pb-4 text-justify leading-7'>So let’s say we’re creating a landing page or any simple web. Do we need to set up a React.Js project with a custom Webpack configuration, tests, styles libraries, libraries for a simple function, libraries for SEO, and sitemaps, just to show one page of content? No, we honestly don’t.</p>

            <h2 className=" text-2xl md:text-4xl font-bold  leading-9 mb-5">What are the pros of using the newest technologies?</h2>

            <p className='pb-4 text-justify leading-7'>Base technologies are often the ones you should be using. But that doesn’t mean you should discount the newest technologies. Let’s go through some of their pros:</p>

            <p className='pb-4 text-justify leading-7'>1. More time-efficient and agile development <br />
2. Easier to find libraries or code that cover the functionalities you need to develop <br />
3. More convenient for collaborative development <br />
4. Potentially easier to maintain <br />
5. Good development practices using patterns</p>

            <h2 className=" text-2xl md:text-4xl font-bold  leading-9 mb-5">How should you face your “Developers’ Syndrome”?</h2>

            <p className='pb-4 text-justify leading-7'>1. Only create code that addresses the specific challenges you face. <br />
2. Look for simple solutions. <br />
3. Only switch to the newest and trendiest technology if it truly adds value to the end product. <br />
4. Before defining the technologies you’ll use for a new project, understand the projects’ scope, and discuss your alternatives with other teammates. <br />
5. Stick to the requirements. <br />
6. Make sure your code is as simple and clear as possible. <br />
7. Don’t use extensive libraries for simple solutions. This will affect UX by slowing down the site’s speed. Libraries like Bootstrap are sometimes used just for a single feature which can be programmed in just three code lines. <br />
8. Only implement best practices if they help you achieve your objectives.</p>

            <p className='pb-4 text-justify leading-7'>All in all, even though it’s essential to keep up with new technologies, it’s also fundamental to understand the clients’ and users’ priorities and the scope of the project before making any decision. And it’s key not to underrate base technologies, in the end, they are the foundations of everything we can build.</p>
          
        </div>

      </section>

      <section className="mb-8">
  <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
    <div className="flex flex-wrap gap-2">
      
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
    </div>
    <div className="flex items-center gap-3 ">
      <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} /> share
        </a>
      </div>
      <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} /> tweet
        </a>
      </div>
      <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
        </a>
      </div>
      <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} /> linkedin
        </a>
      </div>
    </div>
  </div>
</section>
 
    </div>
  )
}

export default DeveloperSyndrome;
