import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const NLP = () => {
  return (
    <div className="bg-[#fff] w-full flex flex-col">
      <section
        style={{ backgroundImage: `url('../images/nlp.png')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
          Artificial Intelligence, Mobile Development, Web Development
        </p>
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">What Is NLP Text Classification?</h1>

        <div className='flex justify-center items-ceter gap-4 mt-10'>
          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faClock} className="w-4" />
            <p className="blog-publish-date">2022-10-9</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faComment} className="w-4" />
            <p className="blog-publish-date">0 Comments</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faBookmark} />
            <p className="blog-publish-date">450 Views</p>
          </div>

        </div>

      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="mx-auto max-w-screen-md">
        <h2 className=" text-2xl md:text-4xl font-bold  leading-9 mb-5">What Is NLP Text Classification?</h2>
          <p className='pb-4 text-justify leading-7'>It’s a fact: human language is incredibly complex and diverse. In addition to speaking over 7,000 languages, humans don’t express themselves linearly. So, have you ever wondered how machines can understand human language?</p>

          <p className='pb-4 text-justify leading-7'><strong>Natural Language Processing (NLP)</strong> is a type of Artificial Intelligence that helps machines learn about unstructured human language. NLP tools process unstructured data and set a structure for a machine to know what to do with it. In other words, NLP helps machines understand, interpret, and manipulate human language. NLP has several practical uses, but today we’ll talk about one of them: Text Classification.</p>

          <p className='pb-4 text-justify leading-7'><strong>Text Classification:</strong> It is the act of assigning a predefined category to a text document. An example could be Gmail’s functionality in which it differentiates Spam emails from important emails. So, basically, what a text classifier does is defining one category (among a list of predetermined categories) to a free-text. Sounds easy, huh?</p>

          <h2 className=" text-2xl md:text-4xl font-bold  leading-9 mb-5">What Is NLP Text Classification?</h2>
          <p className='pb-4 text-justify leading-7'>The process of creating a text classifier isn’t simple at all. We can split  it up into 4 parts:</p>

          <p className='pb-4 text-justify leading-7'><strong>1. Dataset Preparation:</strong><br />
The dataset is the collection of information that will train the machine. Let’s consider a project we’ve been working on. We recently launched a product called Ozmosys that categorizes news for teams. In this case, our dataset was a package of news. To train the machine, we needed to label the data. We chose 7 categories: Banking & Financial Services; Insurance; Legal Services; Life Sciences; Media; Real Estate; and Travel Industry. Around 70%-80% of the data used must be labeled by category for the machine to learn how to classify. The other 30%-20% is used to test if the machine got the labels right: if the results match the label, the machine is working.</p>

            <p className='pb-4 text-justify leading-7'><strong>2. Feature Engineering:</strong><br />
            For each text on the dataset, it’s important to include features. These are a set of predefined characteristics the machine will need to take into account when classifying text. Features might include text length or the number of times the text includes a certain word.</p>

            <p className='pb-4 text-justify leading-7'><strong>3. Model Training:</strong><br />
            Finally, the machine learning model is trained on the labeled dataset.</p>

            <p className='pb-4 text-justify leading-7'><strong>4. Improve Performance of Text Classifier:</strong><br />
            It’s important to keep improving the text classifier to increase accuracy.</p>

            <p className='pb-4 text-justify leading-7'>Some important tips (and challenges) for the dataset:</p>

            <p className='pb-4 text-justify leading-7'>1. The labeled information with which the machine is trained has to be really similar to the unlabelled data the machine will need to classify on its own. So, if you train a machine with sports news and then apply it to political news, it won’t work.</p>
            <p className='pb-4 text-justify leading-7'>The dataset must include text from all the predefined categories. It’s important to include a large amount of labeled data for each category so that the text classifier can correctly learn patterns and insights.</p>
            <p className='pb-4 text-justify leading-7'>3. The dataset text must fully match one and only one category. This means that if you’re training your machine to differentiate between medical and political news, you shouldn’t train it with news about a politician being sick.</p>
            <p className='pb-4 text-justify leading-7'>Unfortunately, some categories have overlap, which could confuse the machine. We faced this hurdle in designing Ozmosys since text from categories such as Banking & Financial Services and Insurance are likely to include similar keywords. We confronted this challenge by implementing subcategories. For instance, Banking & Financial Services was divided into subcategories such as Mortgages and Loans. So after our machine completed the first categorization, it applied a specialized model for each category.</p>
            <p className='pb-4 text-justify leading-7'>It also may happen that, when the machine is running, there’s a certain text that doesn’t fit any of the established categories. In our example, we decided that the machine must categorize only when the fitting probability is {'>'}90%.</p>
            <p className='pb-4 text-justify leading-7'>All in all, even though NLP text classification isn’t as simple as it looks like, studying it is definitely worth it. It’s a trending technology with multiple uses and exponential growth, which is already causing a  big impact in several industries by accelerating and optimizing processes, improving UX, and automatizing jobs. Please comment on which other technology you would like to learn about.</p>


          <p className='pb-4 text-justify leading-7'>If you’d like to learn more about how to create your own solution or develop an MVP for your startup idea, please reach out to our experts.</p>


          
        </div>

      </section>

      <section className="mb-8">
  <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
    <div className="flex flex-wrap gap-2">
      
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
    </div>
    <div className="flex items-center gap-3 ">
      <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} /> share
        </a>
      </div>
      <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} /> tweet
        </a>
      </div>
      <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
        </a>
      </div>
      <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} /> linkedin
        </a>
      </div>
    </div>
  </div>
</section>
 
    </div>
  )
}

export default NLP;