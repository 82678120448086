import { faBookmark, faComment } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faFacebook, faTwitter, faWhatsapp, faLinkedin } from '@fortawesome/free-brands-svg-icons';


const DataRepoMostRecommended = () => {
  return (
    <div className="bg-[#fff] w-full flex flex-col">
      <section
        style={{ backgroundImage: `url('../images/software-development-company.png')`, backgroundSize: "100% 60vh" }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border pt-[65px]">
        <p className="text-white p-1.5 rounded-md text-xs font-bold text-center mb-10 bg-blue-500 mx-auto">
          Software trends, Artificial Intelligence
        </p>
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase leading-9">Data Repo Lauded As One Of The Most Recommended Companies</h1>

        <div className='flex justify-center items-ceter gap-4 mt-10'>
          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faClock} className="w-4" />
            <p className="blog-publish-date">2022-04-10</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faComment} className="w-4" />
            <p className="blog-publish-date">10 Comments</p>
          </div>

          <div className="flex items-center gap-2 text-white">
            <FontAwesomeIcon icon={faBookmark} />
            <p className="blog-publish-date">600 Views</p>
          </div>

        </div>

      </section>

      <section className="body--container w-full px-4 py-8 md:py-10 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="mx-auto max-w-screen-md">

          <p className='pb-4 text-justify leading-7'>We have seen as well as engineered a good number of breakthroughs in our time. However, it still astounds us to discover that as we have aided businesses to grow, our own has significantly flourished meanwhile.</p>

          <p className='pb-4 text-justify leading-7'>When you’re truly dedicated to the success of your client, it’s easy to push your own goals to the back burner, so we’re taking this time to celebrate ourselves! Data repo Software has been acknowledged as one of the Most Recommended Agencies on The Manifest, a company listings and business development platform!</p>

          <p className='pb-4 text-justify leading-7'>The number one objective of developing a site or app is its utility. A working web app should do its job in a fast, accessible, and secure way, aiming to create a seamless user experience. But sometimes developers, perhaps because of their passion, can lose focus and put their own “developers’ experience” ahead of the user’s. This is, of course, a mistake: users’ and clients’ needs should always be the top priority.</p>

          <p className='pb-4 text-justify leading-7'>How we got to this point, though, is a story best told from the very beginning:</p>

          <p className='pb-4 text-justify leading-7'>We entered the market in 2012, partnering with startups that needed help with conceptualizing, building, and launching their MVPs. We also took up projects with already established businesses that sought to digitally transform their operations. </p>

          <p className='pb-4 text-justify leading-7'>We steadily gained traction and built momentum in the years since and, in 2021, received our first ever client review on a widely known B2B ratings platform! Though we’ve served countless customers prior, this particular milestone still spurred us to continue providing top-notch development services for companies within the educational, travel, hospitality, and tech industry.</p>

            <p className='pb-4 text-justify leading-7'>In the same year, Data repo Software saw its efforts pay off as we made it to the ranks of The Most Recommended AI Developers in Ukraine! That our company is also on the list of top-rated companies in the country’s capital, Kyiv, only means that our expertise has undeniable pull both in the city and all over the country!</p>

            <p className='pb-4 text-justify leading-7'>Achievements like this are invigorating to receive on top of the positive feedback from our customers. We’re motivated, more than ever, to innovate and improve not just businesses we partner with but also the industries they belong in, as that is the one true purpose of every tech company!
Got an idea for a project? Tell us about it. We love a good challenge!</p>
          
        </div>

      </section>

      <section className="mb-8">
  <div className="max-w-screen-md mx-auto rounded-md flex justify-between items-center gap-6">
    <div className="flex flex-wrap gap-2">
      
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">Web Development</p>
      <p className="bg-gray-200 text-gray-600 px-2 py-1 rounded-md">AI</p>
    </div>
    <div className="flex items-center gap-3 ">
      <div className="flex items-center bg-[#42599E] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faFacebook} /> share
        </a>
      </div>
      <div className="flex items-center bg-[#55ACEE] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://twitter.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faTwitter} /> tweet
        </a>
      </div>
      <div className="flex items-center bg-[#34AF23] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faWhatsapp} /> whatsapp
        </a>
      </div>
      <div className="flex items-center bg-[#0798CF] p-4 rounded-lg text-white mb-2 max-h-1">
        <a href="https://www.linkedin.com/" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} /> linkedin
        </a>
      </div>
    </div>
  </div>
</section>
 
    </div>
  )
}

export default DataRepoMostRecommended;
