import ContactUsComponent from '../../components/ContactUsComponent';

const deliverables = [
  "Developed an intuitive frontend UI for cashiers to streamline the checkout process",
  "Implemented a robust backend system to manage inventories, employees, invoices, and user accounts",
  "Automated inventory tracking to ensure accurate stock levels",
  "Enabled employee management with role-based access control",
  "Integrated comprehensive invoicing features for easy transaction management",
  "Facilitated user management for seamless account creation and administration",
  "Built store management tools to oversee multiple locations and streamline operations",
  "Provided advanced reporting and analytics for business insights",
  "Ensured system scalability and performance optimization"
];

const technologiesIcon = [
  "../images/react.png",
  "../images/azure.svg",
  "../images/azure-sql.png",
  "../images/nodejs.png",
  "../images/csharp.png",
];

const POSSystem = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(../images/bg-casestudies-box.png)`,
          backgroundSize: "100% 80vh"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[80vh] pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">Bell POS System</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#E5E7EB" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="w-full pt-5 px-8 items-center justify-center bg-gray-200">
        <div className="flex flex-col md:flex-row px-16">
          <div className="md:w-1/2 flex justify-center items-center p-5">
            <img src="../images/pos-1.png" alt="car rental software dashboard" />
          </div>

          <div className="md:w-1/2 flex justify-center items-center p-5">
            <img src="../images/pos-2.png" alt="car rental software dashboard" />
          </div>
        </div>
      </section>

      <section className="w-full items-center justify-center bg-gray-200">
        <div className="max-w-screen-lg mx-auto py-8">
          <div className="px-8 md:px-1 md:pr-2 py-6">
            <div className="mb-4 text-lg font-normal text-center md:text-left text-gray-600">
              <p>
                <strong>Industry:</strong> Retail; Inventory Management; Software
              </p>
              <p>
                <strong>Location:</strong> USA
              </p>
              <p>
                <strong>Areas of expertise:</strong> Back-end; Front-end; Quality assurance; Project management
              </p>
              <p>
                <strong>Technologies:</strong> React, Typescript, Azure, SQL, Node.js, C#
              </p>
              <p>
                <strong>Timeline:</strong> 2017-now
              </p>
            </div>

            <p className="text-xl font-normal text-center md:text-left text-gray-600">
              Developed by Data Repo, our Bell POS System software is designed to streamline retail operations. The solution includes an intuitive frontend UI for cashiers, and a robust backend system to manage inventories, employees, invoices, user accounts, and store management.
            </p>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Product Development and Backend Integration
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600">
                Our Bell POS System simplifies the retail process by providing a user-friendly interface for cashiers and comprehensive backend management tools. The system automates inventory tracking, employee management, invoicing, and user administration, ensuring smooth and efficient store operations.
                <br /><br />
                The Data Repo team collaborated closely with the client to understand their needs and deliver a scalable, reliable, and user-friendly solution. We utilized advanced technologies to enhance functionality and optimize performance.
              </p>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Deliverables
              </h2>
              <ul className="list-disc pl-5 text-xl font-normal text-center md:text-left text-gray-600">
                {deliverables.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Project Results
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600 pb-8">
                The Bell POS System developed by Data Repo has significantly improved the efficiency and effectiveness of retail operations. The system's scalability and performance optimization have enabled the client to handle an increasing number of transactions with ease.
              </p>
              <ul className="list-disc pl-5 text-xl font-normal text-center md:text-left text-gray-600">
                <li>Enhanced system stability and performance.</li>
                <li>Streamlined operations, reducing time and cost.</li>
                <li>Achieved significant business growth and increased revenue.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="body--technologies--container flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6">
          <h1 className="text-4xl font-bold text-black text-left my-4">TECHNOLOGIES</h1>
          <hr />
          <div className="technologies--icon-contaianer justify-center flex flex-row flex-wrap gap-4 pt-16">
            {technologiesIcon.map((item, index) => (
              <img key={index} src={item} alt={item.split("/")[item.split("/").length - 1]} width="100" height="100" className='mr-10' />
            ))}
          </div>
        </div>
      </section>
      <ContactUsComponent />
    </>
  );
}

export default POSSystem;