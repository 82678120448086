import ContactUsComponent from '../../components/ContactUsComponent'

const deliverables = [
  "Completely rebuilt and re-engineered the event management platform",
  "Implementing automated event scheduling and reminders",
  "Developing a robust ticketing system for events",
  "Integrating real-time analytics for event performance tracking",
  "Creating a user-friendly interface for event registration",
  "Implementing a secure payment gateway for ticket sales",
  "Developing mobile apps for event organizers and attendees",
  "Integrating social media sharing capabilities for events",
  "Setting up the software development and business processes"
];

const technologiesIcon = [
  "../images/react.png",
  "../images/azure.svg",
  "../images/azure-sql.png",
  "../images/nodejs.png",
  "../images/csharp.png",
];

const EventManagementSystem = () => {
  return (
    <>
      <section
        style={{
          backgroundImage: `url(../images/bg-casestudies-box.png)`,
          backgroundSize: "100% 80vh"
        }}
        className="hero--container relative flex flex-col justify-center w-full h-[60vh] bg-no-repeat bg-fixed md:bg-origin-border md:h-[80vh] pt-[65px]">
        <h1 className="text-center text-white text-2xl md:text-5xl font-bold uppercase">Event Management System</h1>
        <div className="row--image-style bg-transparent w-full absolute bottom-0 left-0 right-0">
          <svg className="waves w-full h-44" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 300" preserveAspectRatio="none">
            <path fill="rgb(226 232 240)" d="M 1000 280 l 2 -253 c -155 -36 -310 135 -415 164 c -102.64 28.35 -149 -32 -235 -31 c -80 1 -142 53 -229 80 c -65.54 20.34 -101 15 -126 11.61 v 54.39 z"></path>
            <path fill="rgb(100 116 139)" d="M 1000 261 l 2 -222 c -157 -43 -312 144 -405 178 c -101.11 33.38 -159 -47 -242 -46 c -80 1 -153.09 54.07 -229 87 c -65.21 25.59 -104.07 16.72 -126 16.61 v 22.39 z"></path>
            <path fill="#E5E7EB" d="M 1000 300 l 1 -230.29 c -217 -12.71 -300.47 129.15 -404 156.29 c -103 27 -174 -30 -257 -29 c -80 1 -130.09 37.07 -214 70 c -61.23 24 -108 15.61 -126 10.61 v 22.39 z"></path>
          </svg>
        </div>
      </section>

      <section className="w-full items-center justify-center bg-gray-200">
        <div className="max-w-screen-lg mx-auto py-8">
          <div className="px-8 md:px-1 md:pr-2 py-6">
            <div className="mb-4 text-lg font-normal text-center md:text-left text-gray-600">
              <p>
                <strong>Industry:</strong> Event Management; Software Development
              </p>
              <p>
                <strong>Location:</strong> USA
              </p>
              <p>
                <strong>Areas of expertise:</strong> Back-end; Front-end; Quality assurance; Project management; AI
              </p>
              <p>
                <strong>Technologies:</strong> React, Typescript, Azure, SQL, Node.js, C#
              </p>
              <p>
                <strong>Timeline:</strong> 2018-now
              </p>
            </div>

            <p className="text-xl font-normal text-center md:text-left text-gray-600">
              The Event Management System is a comprehensive solution designed to streamline the organization and execution of events. Whether you're planning a small meetup or a large conference, our platform provides all the tools you need to ensure your event runs smoothly.
            </p>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Product Capabilities and Features
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600">
                Our system includes robust features to handle every aspect of event management:
                <br /><br />
                - Automated event scheduling and reminders ensure that no detail is overlooked.
                <br />
                - A robust ticketing system handles everything from registration to payment.
                <br />
                - Real-time analytics provide insights into event performance and attendee engagement.
                <br />
                - A user-friendly interface simplifies event registration and management.
                <br />
                - Mobile apps for both organizers and attendees offer convenience and accessibility.
                <br />
                - Social media sharing capabilities help promote your events and reach a wider audience.
              </p>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Deliverables
              </h2>
              <ul className="list-disc pl-5 text-xl font-normal text-center md:text-left text-gray-600">
                {deliverables.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>

            <div className="pt-8">
              <h2 className="text-black text-2xl md:text-3xl font-bold py-5">
                Project Results
              </h2>
              <p className="text-xl font-normal text-center md:text-left text-gray-600 pb-8">
                Our Event Management System has revolutionized the way events are planned and executed. From reducing administrative burdens to enhancing attendee experience, our platform has delivered exceptional results:
              </p>
              <ul className="list-disc pl-5 text-xl font-normal text-center md:text-left text-gray-600">
                <li>Significantly reduced event planning and execution time.</li>
                <li>Increased attendee satisfaction and engagement through seamless event experiences.</li>
                <li>Enhanced decision-making with real-time analytics and reporting.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="body--technologies--container flex flex-col justify-start items-center py-8 md:py-16 px-8 md:px-12 lg:px-16 xl:px-20 2xl:px-24">
        <div className="w-full md:w-5/6">
          <h1 className="text-4xl font-bold text-black text-left my-4">TECHNOLOGIES</h1>
          <hr />
          <div className="technologies--icon-contaianer justify-center flex flex-row flex-wrap gap-4 pt-16">
            {technologiesIcon.map((item, index) => (
              <img key={index} src={item} alt={item.split("/")[item.split("/").length - 1]} width="100" height="100" className='mr-10' />
            ))}
          </div>
        </div>
      </section>

      <ContactUsComponent />
    </>
  )
}

export default EventManagementSystem;